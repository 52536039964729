import { Avatar, Menu, Space } from 'antd'
import {
  CarryOutOutlined,
  DownOutlined,
  FileTextOutlined,
  LogoutOutlined,
  SnippetsOutlined,
  SolutionOutlined,
  UserOutlined,
} from '@ant-design/icons'
import React from 'react'
import DropdownUI from '../../../components/ui/DropDownUI/DropdownUi'
import classes from '../../../pages/LoginPage/Login.module.scss'
import { useUserSelector } from '../../../store/selectors/auth'
import { NavLink } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import { useAppDispatch } from '../../../store/hooks'
import { authLogout } from '../../../store/auth/thunk'
import { useCrmPermission } from '../../../store/selectors/crmInfo'

interface MenuClickEventHandler {
  key: string
}

export const ManagerProfileDrop = React.memo(() => {
  const user = useUserSelector()
  const dispatch = useAppDispatch()
  const permissions = useCrmPermission()

  const handler = (e: MenuClickEventHandler | undefined) => {
    switch (e?.key) {
      case 'exit': {
        dispatch(authLogout())
        break
      }
      default:
        return
    }
  }

  const ManagerProfile = (
    <Menu
      onClick={handler}
      items={[
        {
          disabled: false,
          key: routersPath.HISTORY_PAGE,
          label: (
            <NavLink to={routersPath.HISTORY_PAGE}>
              <span>История</span>
            </NavLink>
          ),
          icon: <UserOutlined />,
        },
        {
          disabled: false,
          key: routersPath.TASK_PAGE,
          label: (
            <NavLink to={routersPath.TASK_PAGE}>
              <span>Текущая задача</span>
            </NavLink>
          ),
          icon: <SolutionOutlined />,
        },
        {
          disabled: !permissions.tests,
          key: routersPath.MANAGER_TESTS,
          label: (
            <NavLink to={routersPath.MANAGER_TESTS}>
              <span>Тесты</span>
            </NavLink>
          ),
          icon: <CarryOutOutlined />,
        },
        {
          disabled: !permissions.scripts,
          key: routersPath.MANAGER_SCRIPTS,
          label: (
            <NavLink to={routersPath.MANAGER_SCRIPTS}>
              <span>Мои скрипты</span>
            </NavLink>
          ),
          icon: <SnippetsOutlined />,
        },
        {
          disabled: !permissions.generator,
          key: routersPath.DOC_GEN,
          label: (
            <NavLink to={routersPath.DOC_GEN}>
              <span>Генератор документов</span>
            </NavLink>
          ),
          icon: <FileTextOutlined />,
        },
        {
          disabled: !permissions.generator,
          key: routersPath.NEW_DOC_GEN,
          label: (
            <NavLink to={routersPath.NEW_DOC_GEN}>
              <span>Новый генератор документов</span>
            </NavLink>
          ),
          icon: <FileTextOutlined />,
        },
        {
          key: 'divider',
          type: 'divider',
        },
        {
          key: 'exit',
          label: <span>Выход</span>,
          icon: <LogoutOutlined />,
        },
      ].filter((el) => !el.disabled)}
    />
  )
  return (
    <DropdownUI menu={ManagerProfile}>
      <Space style={{ cursor: 'pointer' }}>
        <Avatar size={30} className={classes.avatar} icon={<UserOutlined />} />
        {user?.nickName ? user.nickName : 'Менеджер'}
        <DownOutlined />
      </Space>
    </DropdownUI>
  )
})
