import { AppDispatch, RootState } from '../index'
import { useMessage } from '../../hooks/useMessage'
import { setLoading } from '../auth/reducer'
import { AxiosResponse } from 'axios'
import axiosInstance from '../../config/axios'
import { serverPath } from '../../constants/api-endpoints'
import { errorMapper } from '../../utils/errorMapper'
import { ErrorMessages } from '../../constants/errors'
import { INotificationsDto } from '../../dto/notifications/notifications.dto'
import { readMsg, setMsg } from './reducer'

const NOTIFICATIONS_ON_PAGE = 5

export const getFirstNotifications =
  (isRead?: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { error } = useMessage()
    try {
      dispatch(setLoading(true))
      const {
        data: { rows, count, newNotifications },
      }: AxiosResponse<INotificationsDto> = await axiosInstance.get(serverPath.NOTIFICATIONS, {
        params: {
          pageSize: NOTIFICATIONS_ON_PAGE,
          page: 1,
          isRead,
        },
      })
      dispatch(
        setMsg({
          msg: rows,
          newMsg: newNotifications || 0,
          count,
          page: 1,
        }),
      )
    } catch (e) {
      error(errorMapper(ErrorMessages.UPDATE_DATA, e))
    } finally {
      dispatch(setLoading(false))
    }
  }

export const getNotifications =
  (page: number, isRead?: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { error } = useMessage()
    const stateMsg = getState().notifications

    if ((stateMsg.count === 0 || stateMsg.count > stateMsg.msg.length) && page !== stateMsg.page) {
      try {
        dispatch(setLoading(true))

        const {
          data: { rows, count, newNotifications },
        }: AxiosResponse<INotificationsDto> = await axiosInstance.get(serverPath.NOTIFICATIONS, {
          params: {
            pageSize: NOTIFICATIONS_ON_PAGE,
            page: page,
            isRead,
          },
        })
        const newRows = page === 1 ? rows : [...stateMsg.msg, ...rows]
        dispatch(
          setMsg({
            msg: newRows,
            newMsg: newNotifications || 0,
            count,
            page,
          }),
        )
      } catch (e) {
        error(errorMapper(ErrorMessages.UPDATE_DATA, e))
      } finally {
        dispatch(setLoading(false))
      }
    }
  }

export const readNotification = (id: string) => async (dispatch: AppDispatch) => {
  const { error } = useMessage()

  if (id) {
    try {
      await axiosInstance.patch(serverPath.NOTIFICATIONS + '/' + id)
      await dispatch(readMsg({ id }))
    } catch (e) {
      error(errorMapper(ErrorMessages.UPDATE_DATA, e))
    }
  }
}

export const readAllNotifications = () => async (dispatch: AppDispatch) => {
  const { error } = useMessage()

  try {
    await axiosInstance.put(serverPath.NOTIFICATIONS)
    dispatch(getFirstNotifications()).then()
  } catch (e) {
    error(errorMapper(ErrorMessages.UPDATE_DATA, e))
  }
}
