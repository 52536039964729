import React from 'react'
import { Dropdown } from 'antd'
import './DropdownUi.scss'

interface Props {
  children: JSX.Element
  trigger?: ('click' | 'hover' | 'contextMenu')[]
  // eslint-disable-next-line
  menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

const DropdownUI: React.FC<Props> = ({ children, menu, trigger = ['click'] }) => {
  return (
    <Dropdown overlay={menu} trigger={trigger} overlayClassName={'dropContainer'}>
      {children}
    </Dropdown>
  )
}

export default DropdownUI
