import React, { useCallback } from 'react'
import styles from './DeleteModal.module.scss'
import ButtonUi from '../../ui/ButtonUi/ButtonUi'
import { useModalContext } from '../../../context/modalContext'

interface Props {
  title: string
  handler: () => void
}

const DeleteModal: React.FC<Props> = ({ title, handler }) => {
  const { setModalComponent } = useModalContext()

  const deleteHandler = useCallback(() => {
    handler()
    setModalComponent({ component: null })
  }, [handler])

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.actions}>
        <ButtonUi onClick={() => setModalComponent({ component: null })} color='green'>
          Отмена
        </ButtonUi>
        <ButtonUi onClick={deleteHandler} className={styles.submit} color='red'>
          Удалить
        </ButtonUi>
      </div>
    </div>
  )
}

export default DeleteModal
