import { NavLink } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import DropdownUI from '../../ui/DropDownUI/DropdownUi'
import React from 'react'
import { Menu, Space } from 'antd'
import {
  BarChartOutlined,
  CarryOutOutlined,
  DatabaseOutlined,
  DollarOutlined,
  DownloadOutlined,
  DownOutlined,
  ExportOutlined,
  FileAddOutlined,
  FileTextOutlined,
  HomeOutlined,
  LinkOutlined,
  LockOutlined,
  PhoneOutlined,
  SearchOutlined,
  SettingFilled,
  SettingOutlined,
  SnippetsOutlined,
  SwapOutlined,
  TeamOutlined,
  TransactionOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { useCrmPermission } from '../../../store/selectors/crmInfo'

export interface ITabs {
  title: JSX.Element
  pages: routersPath[]
  show: boolean
}

export const adminTabs = (): ITabs[] => {
  const permissions = useCrmPermission()
  return [
    {
      show: true,
      title: (
        <NavLink to={routersPath.MAIN_PAGE}>
          <HomeOutlined />
          Главная
        </NavLink>
      ),
      pages: [routersPath.MAIN_PAGE],
    },
    {
      show: true,
      title: (
        <DropdownUI
          menu={
            <Menu
              items={[
                {
                  key: routersPath.BASES,
                  label: (
                    <NavLink to={routersPath.BASES}>
                      <span>Мои базы</span>
                    </NavLink>
                  ),
                  icon: <DatabaseOutlined />,
                },
                {
                  key: routersPath.BASES_IMPORT,
                  label: (
                    <NavLink to={routersPath.BASES_IMPORT}>
                      <span>Импорт базы</span>
                    </NavLink>
                  ),
                  icon: <FileAddOutlined />,
                },
                {
                  key: routersPath.BASES_EXPORT,
                  label: (
                    <NavLink to={routersPath.BASES_EXPORT}>
                      <span>Экспорт баз</span>
                    </NavLink>
                  ),
                  icon: <ExportOutlined />,
                },
                {
                  key: routersPath.BASES_CLIENT_FIND,
                  label: (
                    <NavLink to={routersPath.BASES_CLIENT_FIND}>
                      <span>Поиск клиента</span>
                    </NavLink>
                  ),
                  icon: <SearchOutlined />,
                },
                {
                  key: routersPath.BASES_DOUBLE_FIND,
                  label: (
                    <NavLink to={routersPath.BASES_DOUBLE_FIND}>
                      <span>Поиск дублей</span>
                    </NavLink>
                  ),
                  icon: <TeamOutlined />,
                },
              ]}
            />
          }
        >
          <Space>
            <span>
              <DatabaseOutlined />
              Базы данных
            </span>

            <DownOutlined />
          </Space>
        </DropdownUI>
      ),
      pages: [
        routersPath.BASES,
        routersPath.BASE,
        routersPath.BASES_IMPORT,
        routersPath.BASES_CLIENT_FIND,
        routersPath.BASES_DOUBLE_FIND,
        routersPath.CLIENT_PAGE,
      ],
    },
    {
      show: true,
      title: (
        <DropdownUI
          menu={
            <Menu
              items={[
                {
                  disabled: false,
                  key: routersPath.MANAGER_SETTINGS,
                  label: (
                    <NavLink to={routersPath.MANAGER_SETTINGS}>
                      <span>Предустановки</span>
                    </NavLink>
                  ),
                  icon: <SettingFilled />,
                },
                {
                  disabled: false,
                  key: routersPath.MANAGERS_PAGE,
                  label: (
                    <NavLink to={routersPath.MANAGERS_PAGE}>
                      <span>Менеджеры</span>
                    </NavLink>
                  ),
                  icon: <TeamOutlined />,
                },
                {
                  disabled: false,
                  key: routersPath.PERMISSIONS,
                  label: (
                    <NavLink to={routersPath.PERMISSIONS}>
                      <span>Группы доступа</span>
                    </NavLink>
                  ),
                  icon: <LockOutlined />,
                },
                {
                  disabled: !permissions.whiteList,
                  key: routersPath.WHITE_LIST,
                  label: (
                    <NavLink to={routersPath.WHITE_LIST}>
                      <span>Белый список</span>
                    </NavLink>
                  ),
                  icon: <SettingOutlined />,
                },
                {
                  disabled: false,
                  key: routersPath.TELEPHONY,
                  label: (
                    <NavLink to={routersPath.TELEPHONY}>
                      <span>Телефония</span>
                    </NavLink>
                  ),
                  icon: <PhoneOutlined />,
                },
                {
                  disabled: false,
                  key: routersPath.ONLINE_USERS,
                  label: (
                    <NavLink to={routersPath.ONLINE_USERS}>
                      <span>Юзеры онлайн</span>
                    </NavLink>
                  ),
                  icon: <SwapOutlined />,
                },
                {
                  disabled: !permissions.scripts,
                  key: routersPath.SCRIPTS_PAGE,
                  label: (
                    <NavLink to={routersPath.SCRIPTS_PAGE}>
                      <span>Скрипты</span>
                    </NavLink>
                  ),
                  icon: <SnippetsOutlined />,
                },
                {
                  disabled: !permissions.tests,
                  key: routersPath.TESTS,
                  label: (
                    <NavLink to={routersPath.TESTS}>
                      <span>Тесты</span>
                    </NavLink>
                  ),
                  icon: <CarryOutOutlined />,
                },
                {
                  disabled: false,
                  key: routersPath.LINKS,
                  label: (
                    <NavLink to={routersPath.LINKS}>
                      <span>Ссылки</span>
                    </NavLink>
                  ),
                  icon: <LinkOutlined />,
                },
              ]}
            />
          }
        >
          <Space>
            <span>
              <SettingFilled />
              Настройки
            </span>

            <DownOutlined />
          </Space>
        </DropdownUI>
      ),
      pages: [
        routersPath.MANAGER_SETTINGS,
        routersPath.MANAGERS_PAGE,
        routersPath.WHITE_LIST,
        routersPath.ONLINE_USERS,
        routersPath.SCRIPTS_PAGE,
        routersPath.SCRIPT_EDIT,
        routersPath.SCRIPT_CREATE,
        routersPath.TESTS,
        routersPath.ADD_TEST,
        routersPath.UPDATE_TEST,
        routersPath.RESULT_TEST,
        routersPath.SUMMARY_RESULT_TEST,
        routersPath.TELEPHONY,
      ],
    },
    {
      show: permissions.statistic,
      title: (
        <NavLink to={routersPath.STAT_PAGE}>
          <BarChartOutlined />
          Статистика
        </NavLink>
      ),
      pages: [routersPath.STAT_PAGE, routersPath.STAT_MANAGER_PAGE],
    },
    {
      show: permissions.salary,
      title: (
        <DropdownUI
          menu={
            <Menu
              items={[
                {
                  key: routersPath.SALARY_INPUT,
                  label: <NavLink to={routersPath.SALARY_INPUT}>Приход</NavLink>,
                  icon: <DownloadOutlined />,
                },
                {
                  key: routersPath.SALARY_OUTPUT,
                  label: (
                    <NavLink to={routersPath.SALARY_OUTPUT}>
                      <span>Расход</span>
                    </NavLink>
                  ),
                  icon: <UploadOutlined />,
                },
                {
                  key: routersPath.SALARY_DEBT,
                  label: (
                    <NavLink to={routersPath.SALARY_DEBT}>
                      <span>Долги</span>
                    </NavLink>
                  ),
                  icon: <DollarOutlined />,
                },
                {
                  key: routersPath.SALARY_CRYPTO_CHANGE,
                  label: (
                    <NavLink to={routersPath.SALARY_CRYPTO_CHANGE}>
                      <span>Вывод крипты/Расчет ЗП</span>
                    </NavLink>
                  ),
                  icon: <TransactionOutlined />,
                },
              ]}
            />
          }
        >
          <Space>
            <span>
              <DollarOutlined />
              Зарплата
            </span>
            <DownOutlined />
          </Space>
        </DropdownUI>
      ),
      pages: [
        routersPath.SALARY_INPUT,
        routersPath.SALARY_OUTPUT,
        routersPath.SALARY_CALC,
        routersPath.SALARY_CRYPTO_CHANGE,
        routersPath.SALARY_SUMMARY_REPORT,
        routersPath.SALARY_DEBT,
      ],
    },
    {
      show: permissions.generator,
      title: (
        <DropdownUI
          menu={
            <Menu
              items={[
                {
                  key: routersPath.DOC_GEN,
                  label: <NavLink to={routersPath.DOC_GEN}>Генератор документов</NavLink>,
                  icon: <FileTextOutlined />,
                },
                {
                  key: routersPath.NEW_DOC_GEN,
                  label: <NavLink to={routersPath.NEW_DOC_GEN}>Новый генератор документов</NavLink>,
                  icon: <FileTextOutlined />,
                },
              ]}
            />
          }
        >
          <Space>
            <span>
              <FileTextOutlined />
              Генератор документов
            </span>
            <DownOutlined />
          </Space>
        </DropdownUI>
      ),
      pages: [
        routersPath.DOC_GEN,
        routersPath.DOC_PREVIEW,
        routersPath.NEW_DOC_GEN,
        routersPath.NEW_DOC_PREVIEW,
      ],
    },
    // {
    //   title: 'Безопасность',
    //   index: 1,
    //   pages: [routersPath.STAT_PAGE],
    // },
  ]
}
