import { NavLink } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import {
  DollarOutlined,
  DownloadOutlined,
  TransactionOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import React from 'react'

export const salaryTabs = [
  {
    show: true,
    title: (
      <NavLink to={routersPath.SALARY_INPUT}>
        <DownloadOutlined />
        Приход
      </NavLink>
    ),
    pages: [routersPath.SALARY_INPUT],
  },
  {
    show: true,
    title: (
      <NavLink to={routersPath.SALARY_OUTPUT}>
        <UploadOutlined />
        Расход
      </NavLink>
    ),
    pages: [routersPath.SALARY_OUTPUT],
  },
  {
    show: true,
    title: (
      <NavLink to={routersPath.SALARY_DEBT}>
        <DollarOutlined />
        Долги
      </NavLink>
    ),
    pages: [routersPath.SALARY_DEBT],
  },
  {
    show: true,
    title: (
      <NavLink to={routersPath.SALARY_CRYPTO_CHANGE}>
        <TransactionOutlined />
        Вывод крипты/Расчет ЗП
      </NavLink>
    ),
    pages: [routersPath.SALARY_CRYPTO_CHANGE],
  },
]
