export enum bankVariables {
    ADDRESS = '*АДРЕС БАНКА*',
    NAME = '*ИМЯ БАНКА*',
    RECS = '*РЕКВИЗИТЫ БАНКА*',
    RUC = '*РУКОВОДИТЕЛЬ БАНКА*',
    FOOTER1 = '*ФУТТЕР1 БАНКА*',
    FOOTER2 = '*ФУТТЕР2 БАНКА*',
    FOOTER3 = '*ФУТТЕР3 БАНКА*',
    FOOTER4 = '*ФУТТЕР4 БАНКА*',
    FOOTER5 = '*ФУТТЕР5 БАНКА*',
    BIK = '*БИК БАНКА*',
    INN = '*ИНН БАНКА*',
    KPP = '*КПП БАНКА*',
    KORR = '*КОРР БАНКА*',
  }

  export const bankVarArr = () => {
    return Object.keys(bankVariables).map((name) => {
      return bankVariables[name as keyof typeof bankVariables]
    })
  }