import React from 'react'
import './styles/App.scss'
import ruRu from 'antd/es/locale/ru_RU'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import 'moment/locale/ru'
import { RouterComponent } from './router/RouterComponent'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { persistor, store } from './store'

moment.locale('ru')

const App: React.FC = () => {
  return (
    <div className='App'>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConfigProvider locale={ruRu}>
            <RouterComponent />
          </ConfigProvider>
        </PersistGate>
      </Provider>
    </div>
  )
}

export default App
