import React, { useMemo } from 'react'
import { TableProps } from 'antd/lib/table/Table'
import { ITableState, SortEnum } from '../../../utils/sort'
import { Input, Space, Table, TableColumnType } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { ColumnType, FilterValue } from 'antd/es/table/interface'
import styles from './CustomTable.module.scss'
import { CloseOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import ButtonUi from '../ButtonUi/ButtonUi'
import cn from 'classnames'
import { ITableLocalSearch } from '../../../interfaces/table.interface'

interface Props extends TableProps<any> {
  columns: (ColumnsType<any> & { isSearch?: boolean }) | undefined
  dataSource: any[]
  editTitle?: string
  allCount?: number
  customKey?: string
  setFieldSearch?: ({ search, searchField, searchFieldValue }: ITableLocalSearch) => void
  fieldSearch?: ITableLocalSearch
  updatePagination?: React.Dispatch<React.SetStateAction<ITableState>>
  delAction?: (val: string) => void
  editAction?: (val: string) => void
}

const CustomTable: React.FC<Props> = ({
  delAction,
  editAction,
  updatePagination,
  allCount = 0,
  columns,
  setFieldSearch,
  fieldSearch,
  pagination,
  dataSource,
  editTitle,
  ...props
}) => {
  const mappedData = useMemo(() => {
    return dataSource && (delAction || editAction)
      ? dataSource.map((el) => ({
          ...el,
          action: (
            <div className={styles.actions}>
              {editAction && !el?.hideEdit && (
                <span className={styles.edit} onClick={() => editAction(el.id)}>
                  <EditOutlined />
                </span>
              )}
              {delAction && !el?.hideDel && (
                <span className={styles.del} onClick={() => delAction(el.id)}>
                  <CloseOutlined />
                </span>
              )}
            </div>
          ),
        }))
      : dataSource
  }, [dataSource, delAction, editAction])
  const getColumnSearchProps = (dataIndex: string): TableColumnType<string> => ({
    filterDropdown: ({ clearFilters }) => (
      <div className={styles.fieldSearch} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          autoFocus
          // placeholder={`Search ${String(dataIndex)}`}
          value={
            fieldSearch?.searchField &&
            fieldSearch?.searchFieldValue &&
            fieldSearch?.searchField === String(dataIndex)
              ? fieldSearch?.searchFieldValue
              : ''
          }
          onChange={(e) =>
            setFieldSearch &&
            setFieldSearch({
              search: '',
              searchField: String(dataIndex),
              searchFieldValue: e.target.value,
            })
          }
        />
        <Space className={styles.actionsSearch}>
          <ButtonUi
            onClick={() => {
              setFieldSearch &&
                setFieldSearch({
                  search: '',
                  searchField: '',
                  searchFieldValue: '',
                })
            }}
            size='small'
          >
            Reset
          </ButtonUi>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <SearchOutlined
        className={cn(styles.searchIcon, {
          [styles.active]: fieldSearch?.searchField === dataIndex && fieldSearch?.searchFieldValue,
        })}
      />
    ),
  })

  const mappedColumns = useMemo(() => {
    const mapped = columns?.map((el: ColumnType<string> & { isSearch?: boolean }) => {
      const searchProps = el?.isSearch && getColumnSearchProps(el?.dataIndex as string)
      return {
        ...el,
        width: el.width ?? `${100 / columns.length}%`,
        ...searchProps,
      }
    })

    return mapped && (delAction || editAction)
      ? [
          ...mapped,
          {
            title: editTitle ?? 'Редактировать',
            dataIndex: 'action',
            fixed: 'right' as const,
          },
        ]
      : mapped
  }, [columns, delAction, editAction, getColumnSearchProps])

  const changeHandler = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
  ) => {
    updatePagination &&
      updatePagination((prev: ITableState) => ({
        ...prev,
        sort: {
          sort: sorter?.order ? (sorter.order === 'ascend' ? SortEnum.asc : SortEnum.desc) : null,
          field: sorter?.field && sorter?.order ? sorter.field : null,
        },
        pagination: {
          current: pagination?.current ?? prev.pagination.current,
          pageSize: pagination?.pageSize ?? prev.pagination.pageSize,
        },
        filters,
      }))
  }

  return (
    <Table
      showSorterTooltip={false}
      className={styles.customTable}
      dataSource={mappedData}
      rowKey={(record) => record.key}
      columns={mappedColumns}
      getPopupContainer={() => document.getElementById('root') || document.body}
      pagination={
        pagination
          ? {
              ...pagination,
              pageSizeOptions: [5, 10, 20, 50],
              showSizeChanger: true,
              total: allCount,
            }
          : false
      }
      onChange={changeHandler}
      {...props}
    />
  )
}

export default CustomTable
