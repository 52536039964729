export enum USER_PERMISSIONS {
  EOG = '1d1e7948-8c76-45fe-a332-aa29857d6493',
  CYBERSEC = '8ce3c49d-387c-4314-9522-ff6690f4f1bb',
}

export interface IUserPermission {
  id: USER_PERMISSIONS
  name: string
  description: string
}

export const userPermissionsList: IUserPermission[] = [
  {
    id: USER_PERMISSIONS.EOG,
    name: 'Глаз бога',
    description:
      'Это правило предоставляет менеджеру возможность использовать функционал "глаза бога".',
  },
  {
    id: USER_PERMISSIONS.CYBERSEC,
    name: 'Cybersec',
    description:
      'Это правило предоставляет менеджеру возможность использовать функционал "киберсек".',
  },
]
