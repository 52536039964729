import React from 'react'
import { Menu } from 'antd'
import {
  BarChartOutlined,
  LogoutOutlined,
  // PoweroffOutlined,
  DollarCircleOutlined,
  SnippetsOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { routersPath } from '../../../../router/routes-path'
import { NavLink } from 'react-router-dom'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { useCrmPermission } from '../../../../store/selectors/crmInfo'
import { ItemType } from 'antd/es/menu/hooks/useItems'

export const AdminProfile = (handler: MenuClickEventHandler) => {
  const permissions = useCrmPermission()
  const items = [
    {
      key: 'profile',
      label: <span>Профиль</span>,
      icon: <UserOutlined />,
    },
    {
      disabled: !permissions.statistic,
      key: routersPath.STAT_PAGE,
      label: (
        <NavLink to={routersPath.STAT_PAGE}>
          <span>Статистика</span>
        </NavLink>
      ),
      icon: <BarChartOutlined />,
    },
    {
      key: routersPath.MANAGERS_PAGE,
      label: (
        <NavLink to={routersPath.MANAGERS_PAGE}>
          <span>Менеджеры</span>
        </NavLink>
      ),
      icon: <TeamOutlined />,
    },
    {
      disabled: !permissions.scripts,
      key: routersPath.SCRIPTS_PAGE,
      label: (
        <NavLink to={routersPath.SCRIPTS_PAGE}>
          <span>Скрипты</span>
        </NavLink>
      ),
      icon: <SnippetsOutlined />,
    },
    {
      key: 'pay',
      label: <span>Оплатить CRM</span>,
      icon: <DollarCircleOutlined />,
    },
    // {
    //   key: 'powerOff',
    //   label: <span>Выключить CRM</span>,
    //   icon: <PoweroffOutlined />,
    //   disabled: true,
    // },
    {
      type: 'divider',
    },
    {
      key: 'exit',
      label: <span>Выход</span>,
      icon: <LogoutOutlined />,
    },
  ].filter((el) => !el.disabled)
  return <Menu onClick={handler} items={items as ItemType[] | undefined} />
}
