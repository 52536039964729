import React from 'react'
import cls from './BannerSection.module.scss'

const BannerSection: React.FC = () => {
  return (
    <div className={cls.container}>
      <a href='http://t.me/moriarti_base' target='_blank' rel='noreferrer'>
        <img className={cls.banner} src='/assets/webp/banner2.webp' alt='banner2' />
      </a>
      <a href='http://t.me/carl_obnaal' target='_blank' rel='noreferrer'>
        <video
          className={cls.banner}
          src='/assets/webm/banner1.webm'
          playsInline={true}
          autoPlay
          muted
          loop
        />
      </a>
      <a href='http://t.me/vsesvoi111111111' target='_blank' rel='noreferrer'>
        <video
          className={cls.banner}
          src='/assets/webm/olio.webm'
          playsInline={true}
          autoPlay
          muted
          loop
        />
      </a>
      <a href='http://t.me/GoldenTel' target='_blank' rel='noreferrer'>
        <video
          className={cls.banner}
          src='/assets/webm/GoldenTel.webm'
          playsInline={true}
          autoPlay
          muted
          loop
        />
      </a>
    </div>
  )
}

export default BannerSection
