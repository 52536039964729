import moment from 'moment'

export const calcTime = (offset?: number): string | null => {
  if (!offset) return null
  // create Date object for current location
  const d = new Date()

  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  const utc = d.getTime() + d.getTimezoneOffset() * 60000

  // create new Date object for different city
  // using supplied offset
  const nd = new Date(utc + 3600000 * offset)

  // return time as a string
  // return nd.toLocaleString()
  return (
    (nd.getHours() < 10 ? '0' : '') +
    nd.getHours() +
    ':' +
    (nd.getMinutes() < 10 ? '0' : '') +
    nd.getMinutes()
  )
}

export const formatData = (val: string) => {
  return new Date(val).toLocaleString('ru-RU')
}

export const shortDateFormat = (val: string) => moment(val).format('DD.MM.YYYY')
export const fullDateFormat = (val: string) => moment(val).format('DD.MM.YYYY-HH:mm')

export const Timeout = (delay: number) => {
  return new Promise((res) => setTimeout(res, delay))
}
