import { createContext, Dispatch, SetStateAction, useContext } from 'react'
import { IReportInfo } from '../dto/salary/salary.dto'

export interface ISalaryStatuses {
  isConfirm: boolean
  isPreview: boolean
  currentReport?: IReportInfo
}

export interface ISalaryStatusesContext {
  salaryStatuses: ISalaryStatuses
  setSalaryStatuses: Dispatch<SetStateAction<ISalaryStatuses>>
}

export interface ISalaryStatusesWithConfirmContext extends ISalaryStatusesContext {
  isConfirm: boolean
  currentReport?: IReportInfo
}

export const SalaryStatusesContext = createContext<ISalaryStatusesContext>(
  {} as ISalaryStatusesContext,
)
export const useSalaryStatusesContext = (): ISalaryStatusesWithConfirmContext => {
  const { salaryStatuses, setSalaryStatuses } = useContext(SalaryStatusesContext)
  return {
    salaryStatuses,
    setSalaryStatuses,
    currentReport: salaryStatuses?.currentReport,
    isConfirm: salaryStatuses.isConfirm || !salaryStatuses.isPreview,
  }
}
