import { Avatar, Space } from 'antd'
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import React, { useMemo } from 'react'
import DropdownUI from '../../../components/ui/DropDownUI/DropdownUi'
import classes from '../../../pages/LoginPage/Login.module.scss'
import { useUserSelector } from '../../../store/selectors/auth'
import { useNavigate } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import { useAppDispatch } from '../../../store/hooks'
import { authLogout } from '../../../store/auth/thunk'
import { useModalContext } from '../../../context/modalContext'
import ManagerUpdateModal from '../../../components/Modals/ManagerUpdate/ManagerUpdate.modal'
import { EUserRoles } from '../../../enums/roles'
import { AdminProfile } from './dropMenu/adminDropMenu'
import { OperatorProfile } from './dropMenu/operatorDropMenu'
import PayModal from '../../../components/Modals/PayModal/PayModal'

interface MenuClickEventHandler {
  key: string
}
export const AdminProfileDrop = () => {
  const user = useUserSelector()
  const dispatch = useAppDispatch()
  const { setModalComponent } = useModalContext()
  const navigator = useNavigate()

  const handler = (e: MenuClickEventHandler | undefined) => {
    switch (e?.key) {
      case 'profile': {
        user &&
          setModalComponent({
            component: (
              <ManagerUpdateModal
                manager={user}
                update={() => {
                  navigator(routersPath.LOGIN_PAGE)
                }}
              />
            ),
            maskClosable: true,
          })
        break
      }
      case 'exit': {
        dispatch(authLogout())
        break
      }
      case 'powerOff': {
        console.log('Power OFF CRM')
        break
      }
      case 'pay': {
        setModalComponent({
          component: <PayModal />,
          maskClosable: true,
        })
        break
      }
      default:
        return
    }
  }

  const userName = useMemo(() => {
    switch (user?.role) {
      case EUserRoles.ADMIN:
      case EUserRoles.OPERATOR:
        return user?.crm?.name ?? 'Пользователь'
    }
    return 'Пользователь'
  }, [user])

  const dropMenu = () => {
    switch (user?.role) {
      case EUserRoles.ADMIN:
        return AdminProfile(handler)
      case EUserRoles.OPERATOR:
        return OperatorProfile(handler)
    }
    return AdminProfile(handler)
  }

  return (
    <DropdownUI menu={dropMenu()}>
      <Space style={{ cursor: 'pointer' }}>
        <Avatar size={30} className={classes.avatar} icon={<UserOutlined />} />
        {userName}
        <DownOutlined />
      </Space>
    </DropdownUI>
  )
}
