import cls from './EditorDoc.module.scss'
import React, { useCallback } from 'react'
import { Tiny } from './components/Tiny/Tiny'
import { ImgEdit } from './components/ImgEdit/ImgEdit'
import { LogoEdit } from './components/LogoEdit/LogoEdit'
import { classNames } from '../../../../../../utils/classNames'
import { ElementType, IElem } from '../../../../../DocTemplates/templates.interface'

export const tinyApiKey = '75e097c5d1472bd795d0e495c0a6688d36adf3f222bf04ae7e2badc76da39004'

interface EditorDocProps {
  className?: string
  element?: IElem
}

export const EditorDoc: React.FC<EditorDocProps> = ({ element, className = '' }) => {
  if (!element) return null

  const render = useCallback(() => {
    switch (element.type) {
      case ElementType.TEXT:
        return <Tiny element={element} />
      case ElementType.IMG:
        return <ImgEdit element={element} />
      case ElementType.STAMP:
      case ElementType.LOGO:
        return <LogoEdit element={element} />
    }
  }, [element])

  return <div className={classNames([cls.EditorDoc, className])}>{render()}</div>
}
