import { Spin } from 'antd'
import React, { DetailedHTMLProps } from 'react'
import styles from './PageSpinner.module.scss'
import cn from 'classnames'

const PageSpinner: React.FC<
  DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
  return (
    <div {...props} className={cn(styles.container, className)}>
      <Spin size='large' tip='Загрузка...' />
    </div>
  )
}

export default PageSpinner
