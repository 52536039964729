export const columnsPermissionsTemplatesPage = [
  {
    title: 'Название',
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    sorter: true,
  },
  {
    title: 'Кол-во правил',
    dataIndex: 'permissions',
    sorter: true,
  },
  {
    title: 'Кол-во пользователей',
    dataIndex: 'users',
    sorter: true,
  },
]
