import { useAppSelector } from '../hooks'
import moment from 'moment'

export const useCrmInfoSelector = () => useAppSelector((state) => state.crmInfo)
export const useCrmPermission = () => useAppSelector((state) => state.crmInfo.permission)
export const useEndSubscription = () => {
  const crm = useAppSelector((state) => state.crmInfo)
  return moment().isAfter(moment(crm?.billingDate))
}
export const useBalance = () => useAppSelector((state) => state.crmInfo.balance)
export const useLinks = () => useAppSelector((state) => state.links.linksList)
