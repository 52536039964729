export enum BankName {
  ABSOLUT = 'absolut',
  AKBARS = 'akbars',
  ALFA = 'alfa',
  ATB = 'atb',
  AVANGARD = 'avangard',
  // BKS = 'bks',
  BSPB = 'bspb',
  CBR = 'cbr',
  // CITY = 'city',
  // DOMRF = 'domrf',
  GAZPROM = 'gazprom',
  // GENBANK = 'genbank',
  HLINOV = 'hlinov',
  HOMECREDIT = 'homecredit',
  NBRB = 'nbrb',
  LOCKOBANK = 'lockobank',
  MINBANK = 'minbank',
  MKB = 'mkb',
  MTS = 'mts',
  // NIKO = 'niko',
  // NBK = 'nbk',
  OPEN = 'open',
  // OTP = 'otp',
  POCHTA = 'pochta',
  PSBANK = 'psbank',
  RAIFFEISEN = 'raiffeisen',
  // RENCREDIT = 'rencredit',
  RNCB = 'rncb',
  ROSBANK = 'rosbank',
  // ROSTRAH = 'rostrah',
  RSB = 'rsb',
  RSHB = 'rshb',
  SBER = 'sber',
  // SETELEM = 'setelem',
  // SKBBANK = 'skbbank',
  SOVCOMBANK = 'sovcombank',
  TINKOFF = 'tinkoff',
  UBRR = 'ubrr',
  UNICREDIT = 'unicredit',
  URALSIB = 'uralsib',
  // VBR = 'vbr',
  VTB = 'vtb',
  FSSP = 'fssp',
  // ZAPSIB = 'zapsib',
}
