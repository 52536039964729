import { EUserRoles } from '../../enums/roles'
import { ExpensesReasonEnum } from '../../enums/salary'

export enum IncomeCurrency {
  BTC = 'btc',
  USDT = 'usdt',
}

export enum ExpensesCurrency {
  BTC = 'btc',
  USDT = 'usdt',
  USD = 'usd',
  UAH = 'uah',
}

export enum SalaryTypeEnum {
  REGULAR = 'regular',
  SATURDAY = 'saturday',
}

export interface SalaryDto {
  amountPayable: number
  currency: string
  percentage: number
  dateOfCreation: string
  supportPercentage: number
  managers: {
    id: string
    amountCome: number
    comment: string
  }[]
}

export interface SalaryOutputDto {
  amountPayable: number
  comment: string
  currency: ExpensesCurrency
  dateOfCreation: string
}

export interface IIncome {
  amountCome: number
  amountPayable: number
  comment: string
  createdAt: string
  crmId: string
  currency: IncomeCurrency
  dateOfCreation: string
  id: string
  isConfirm: boolean
  updatedAt: string
}

export interface SalaryGetDto {
  reason?: ExpensesReasonEnum
  amountCome: number
  amountPayable: number
  comment: string
  createdAt: string
  currency: IncomeCurrency
  dateOfCreation: string
  id: string
  isHelper: boolean
  isConfirm: boolean
  payout: number
  percentage: number
  updatedAt: string
  salary: number
  income: IIncome
  type?: SalaryTypeEnum
  user: {
    id: string
    nickName: string
  }
  userId: string
}

export interface SalaryCryptoCreate {
  dateOfCreation: string
  currency: string
  amountPayable: number
  rate: number
  onHand: number
  commission: number
}

export interface SalaryCryptoGet {
  amountPayable: number
  commission: number
  createdAt: string
  currency: string
  id: string
  onHand: number
  rate: number
  type: TransferTypeEnum
}

export interface IReportInfo {
  totalIncomeBtc: number
  totalIncomeUsdt: number
  uaRate: number
}

export interface SalaryTotalGet {
  currentReport: null | IReportInfo
  getTotalIncome: {
    totalUsdt: number
    totalBtc: number
    totalAll: number
    totalSaturday?: number
  }
  getTotalExpenses: {
    totalUsdt: number
    totalBtc: number
    totalAll: number
  }
  getSalaryManagers: {
    managers: number
    closer: number
    totalAll: number
  }
  isConfirm: boolean
  isPreview: boolean
}

export interface IReportUser {
  userId: string
  nickName: string
  role: EUserRoles
  totalUa: number
  totalUsdt: number
}

export enum TransferTypeEnum {
  REGULAR = 'regular',
  OUTPUT = 'output',
}
