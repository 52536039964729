import React, { useState } from 'react'
import cls from './AddPermissionTemplate.module.scss'
import TitleUi from '../../../../components/ui/TitleUi/TilteUi'
import { Divider, Form, Input } from 'antd'
import ButtonUi from '../../../../components/ui/ButtonUi/ButtonUi'
import { useModalContext } from '../../../../context/modalContext'
import WithLabel from '../../../../components/ui/WithLabel/WithLabel'
import TextArea from 'antd/lib/input/TextArea'
import { useMessage } from '../../../../hooks/useMessage'
import axiosInstance from '../../../../config/axios'
import { serverPath } from '../../../../constants/api-endpoints'
import { errorMapper } from '../../../../utils/errorMapper'
import { ErrorMessages } from '../../../../constants/errors'

interface IProps {
  refresh: () => void
}

export const AddPermissionTemplate: React.FC<IProps> = ({ refresh }) => {
  const [disable, setDisable] = useState(false)
  const { setModalComponent } = useModalContext()
  const { error, success } = useMessage()

  const createHandler = async (values: { name: string; description: string }) => {
    try {
      setDisable(true)
      await axiosInstance.post(serverPath.PERMISSIONS_TEMPLATES, values)
      success('Группа разрешений создан успешно')
      refresh()
      setModalComponent({ component: null })
    } catch (e) {
      error(errorMapper(ErrorMessages.CREATE_DATA, e), 'Ошибка создания группы разрешений')
    } finally {
      setDisable(false)
    }
  }

  return (
    <Form name='add_permissions' className={cls.AddPermissionTemplate} onFinish={createHandler}>
      <div className={cls.header}>
        <TitleUi colored={false}>Добавить группу разрешений</TitleUi>
      </div>
      <Divider />
      <div className={cls.body}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'введите имя группы',
            },
            {
              min: 3,
              message: 'минимальная длинна 3 символа',
            },
          ]}
          validateTrigger='onSubmit'
          name='name'
        >
          <WithLabel label={'Имя группы:'}>
            <Input placeholder='введите имя группы' disabled={disable} />
          </WithLabel>
        </Form.Item>
        <Form.Item
          rules={[
            {
              min: 3,
              message: 'минимальная длинна 3 символа',
            },
          ]}
          validateTrigger='onSubmit'
          name='description'
        >
          <WithLabel label={'Описание группы:'}>
            <TextArea rows={3} placeholder='Описание группы' />
          </WithLabel>
        </Form.Item>
      </div>
      <Divider />
      <div className={cls.actions}>
        <ButtonUi onClick={() => setModalComponent({ component: null })} color='red'>
          Отмена
        </ButtonUi>
        <ButtonUi htmlType='submit' className={cls.submit} color='green' disabled={disable}>
          Создать группу
        </ButtonUi>
      </div>
    </Form>
  )
}
