import React, { useEffect, useMemo, useState } from 'react'
import cls from './ImgEdit.module.scss'
import { useElementsContext } from '../../../../../../context/elementsContext'
import { classNames } from '../../../../../../../../utils/classNames'
import { SliderUi } from '../../../../../../../../components/ui/SliderUi/SliderUi'
import { Select } from 'antd'
import {
  AdditionalMapper,
  IImgMapper,
  ImagesMapper,
  PhotoMapper,
  SignatureMapper,
  StampMapper,
} from '../../../../constants/mapper'
import { ImgType } from '../../../../constants/img'
import { IElem } from '../../../../../../../DocTemplates/templates.interface'

const { Option } = Select
interface ImgEditProps {
  className?: string
  element: IElem
}

export const ImgEdit: React.FC<ImgEditProps> = ({ className = '', element }) => {
  const [type, setType] = useState(ImgType.STAMP)
  const { setCurrentItem } = useElementsContext()

  const imgUpdate = (val: string) => {
    setCurrentItem((prev) => {
      const filtered = prev.template.filter((el) => el.id !== element.id)
      return {
        ...prev,
        template: [
          ...filtered,
          {
            ...element,
            width: Number(val),
          },
        ],
      }
    })
  }

  const rotateUpdate = (val: string) => {
    setCurrentItem((prev) => {
      const filtered = prev.template.filter((el) => el.id !== element.id)
      return {
        ...prev,
        template: [
          ...filtered,
          {
            ...element,
            rotate: Number(val),
          },
        ],
      }
    })
  }

  useEffect(() => {
    const initSignature = Object.values(SignatureMapper).map((el) => el.src)
    const initPhoto = Object.values(PhotoMapper).map((el) => el.src)
    const initStamp = Object.values(StampMapper).map((el) => el.src)
    const Additional = Object.values(AdditionalMapper).map((el) => el.src)

    if (initSignature.includes(element.value)) {
      setType(ImgType.SIGNATURE)
    }
    if (initPhoto.includes(element.value)) {
      setType(ImgType.FOTO)
    }
    if (initStamp.includes(element.value)) {
      setType(ImgType.STAMP)
    }
    if (Additional.includes(element.value)) {
      setType(ImgType.ADDITION)
    }
  }, [element])

  const imgList: any = useMemo(() => {
    switch (type) {
      case ImgType.SIGNATURE:
        return SignatureMapper
      case ImgType.FOTO:
        return PhotoMapper
      case ImgType.STAMP:
        return StampMapper
      case ImgType.ADDITION:
        return AdditionalMapper
    }
  }, [type])

  const selected = useMemo(() => {
    const array: IImgMapper[] = Object.values(imgList)
    return array.find((el) => el?.src === element.value)?.src
  }, [element, imgList])

  const imgSrc = (val: string) => {
    val &&
      setCurrentItem((prev) => {
        const filtered = prev.template.filter((el) => el.id !== element.id)
        return {
          ...prev,
          template: [
            ...filtered,
            {
              ...element,
              value: val,
            },
          ],
        }
      })
  }

  return (
    <div className={classNames([cls.ImgEdit, className])}>
      <SliderUi
        label='Ширина:'
        min={5}
        max={100}
        onChange={(e) => imgUpdate(String(e))}
        value={element.width ?? 10}
      />
      <SliderUi
        label='Поворот:'
        min={0}
        max={360}
        onChange={(e) => rotateUpdate(String(e))}
        value={element.rotate ?? 0}
      />
      <p className={cls.label}>Тип изображения:</p>
      <Select className={cls.typeSelect} onChange={(e) => setType(e)} value={type}>
        {Object.keys(ImagesMapper).map((el) => (
          <Option value={el} key={el}>
            {ImagesMapper[el as ImgType]}
          </Option>
        ))}
      </Select>
      <p className={cls.label}>Выберите элемент:</p>
      <Select
        className={cls.typeSelect}
        allowClear={false}
        placement='topLeft'
        onChange={(e) => imgSrc(e)}
        placeholder='элемент'
        showSearch
        value={selected}
        filterOption={(input, option) =>
          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {Object.keys(imgList).map((el: any) => {
          return (
            <Option value={imgList[el]?.src} key={el}>
              {imgList[el]?.label}
            </Option>
          )
        })}
      </Select>
    </div>
  )
}
