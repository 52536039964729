import React, { useMemo } from 'react'
import { EUserRoles } from '../../enums/roles'
import AdminHeader from './AdminHeader/AdminHeader'
import ManagerHeader from './ManagerHeader/ManagerHeader'
import { useUserSelector } from '../../store/selectors/auth'
import { adminTabs } from './AdminHeader/adminTabs'
import { operatorTabs } from './AdminHeader/operatorTabs'

const useHeader = () => {
  const user = useUserSelector()
  const render = (): React.ReactNode => {
    switch (user?.role) {
      case EUserRoles.ADMIN: {
        return <AdminHeader tabs={adminTabs().filter((el) => el.show)} />
      }
      case EUserRoles.OPERATOR: {
        return <AdminHeader tabs={operatorTabs().filter((el) => el.show)} />
      }
      case EUserRoles.MANAGER: {
        return <ManagerHeader />
      }
      default:
        return <ManagerHeader />
    }
  }
  return <>{render()}</>
}

export default useHeader
