import { FilterValue } from 'antd/es/table/interface'
import moment from 'moment/moment'

export enum SortEnum {
  asc = 'asc',
  desc = 'desc',
}

export interface ISort {
  sort: SortEnum | null
  field: string | null
}

export interface IPagination {
  current: number
  pageSize: number
}

export interface ITableState {
  filters?: Record<string, FilterValue | null>
  sort: ISort
  pagination: IPagination
  search?: string | null
  baseIds?: string[]
  weekFilter?: [moment.Moment | null, moment.Moment | null]
}

// export const getNextSort = (sort: ISort, field: string): ISort => {
//   if (sort.field === field) {
//     switch (sort.sort) {
//       case SortEnum.asc: {
//         return { sort: SortEnum.desc, field: sort.field }
//       }
//       case SortEnum.desc: {
//         return { sort: null, field: null }
//       }
//     }
//   }
//   return { sort: SortEnum.asc, field: field }
// }
