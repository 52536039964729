import { useCallback } from "react"
import { bankVariables } from "../banks/variables"
import { docPreset } from "../../../../../constants/docs/docPreset"
import { useElementsContext } from "../../../context/elementsContext"
import { ITemplate } from "../../../../DocTemplates/templates.interface"

const useBankParser = (current?:ITemplate  ) => {
  const { currentItem } = useElementsContext()
  const bank = currentItem?.bank ?? current?.bank
  const textParser = useCallback(
    (text: string) => {
      if (!bank) {return text} else {
        return text.replaceAll(bankVariables.ADDRESS, docPreset[bank].address)
        .replaceAll(bankVariables.NAME, docPreset[bank].name)
        .replaceAll(bankVariables.RECS, docPreset[bank].recs)
        .replaceAll(bankVariables.RUC, docPreset[bank].valueruc)
        .replaceAll(bankVariables.FOOTER1, docPreset[bank].footer1)
        .replaceAll(bankVariables.FOOTER2, docPreset[bank].footer2)
        .replaceAll(bankVariables.FOOTER3, docPreset[bank].footer3)
        .replaceAll(bankVariables.FOOTER4, docPreset[bank].footer4)
        .replaceAll(bankVariables.FOOTER5, docPreset[bank].footer5)
        .replaceAll(bankVariables.BIK, docPreset[bank].bik)
        .replaceAll(bankVariables.INN, docPreset[bank].inn)
        .replaceAll(bankVariables.KPP, docPreset[bank].kpp)
        .replaceAll(bankVariables.KORR, docPreset[bank].korr)
      }
    },
    [bank],
  )

  return textParser
}

export default useBankParser
