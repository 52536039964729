import { createContext, Dispatch, SetStateAction, useContext } from 'react'
import { SalaryCryptoDto } from '../pages/Salary/SalaryCrypto/SalaryCryptoPage'
import { TransferTypeEnum } from '../dto/salary/salary.dto'

export interface ISalaryTransferContext {
  salaryTransfer: SalaryCryptoDto | undefined
  setSalaryTransfer: Dispatch<SetStateAction<SalaryCryptoDto | undefined>>
}

export interface ISalaryTransferWithConfirmContext extends ISalaryTransferContext {
  isConfirmTransfer: boolean
}

export const SalaryTransferContext = createContext<ISalaryTransferContext>(
  {} as ISalaryTransferContext,
)
export const useSalaryTransferContext = (): ISalaryTransferWithConfirmContext => {
  const { salaryTransfer, setSalaryTransfer } = useContext(SalaryTransferContext)
  return {
    salaryTransfer,
    setSalaryTransfer,
    isConfirmTransfer: Boolean(
      salaryTransfer?.rows &&
        salaryTransfer?.rows.find((el) => el.type === TransferTypeEnum.REGULAR),
    ),
  }
}
