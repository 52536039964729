import { BankName } from '../../enums/banks'

export interface IBankDocs {
  address: string
  name: string
  nameupper: string
  nameuppereng: string
  namepost: string
  recs: string
  valueruc: string
  footer1: string
  footer2: string
  footer3: string
  footer4: string
  footer5: string
  color: string
  bik: string
  inn: string
  kpp: string
  korr: string
  img: string
  stamp: string
}

export type IDocPreset = {
  [key in BankName]: IBankDocs
}

export const getBankCurrency = (name: BankName) => {
  if (name === BankName.NBRB) {
    return {
      currency: {
        main: 'Рубли (RUB)',
        amount: 'рублей',
      },
      country: 'БЕЛАРУСЬ',
      countryeng: 'BELARUS',
      pennies: '00 копеек',
      check: '40817810567102536002',
      card: '2022 0213 4009 4511',
      branch: 'г. Минск, пр. Независимости 20',
      article: 'РБ',
      organ: 'КГБ',
      atm: 'ПАО "Сбербанк" по адресу: г. Минск, ул. Кальварийская 4а',
      partner: 'Альфа-Банк',
      telephone: '+375 17 890-10-02',
      collection: 'белорусское управление инкасации  "Белинкасгрупп"',
      central: 'Национального Банка РБ',
      recipient: {
        payeeBank: 'Операционное Отделение № 15/19 АО «Альфа-Банк»',
        cell: '9784651274',
        cpp: '770801001',
        bik: '044525593',
        inn: '7728168971',
        cor: {
          title: 'Корр.сч.:',
          value: '30101810200000000593',
        },
      },
    }
  }
  return {
    currency: {
      main: 'Рубли (RUB)',
      amount: 'рублей',
    },
    country: 'РОССИЯ',
    countryeng: 'RUSSIA',
    pennies: '00 копеек',
    check: '40817810567102536002',
    card: '2022 0213 4009 4511',
    branch: 'г. Обнинск, пр-т К.Маркса, 4 (№8608/0234)',
    article: 'РФ',
    organ: 'МВД',
    atm: 'АО "Тинькофф" по адресу: г.Обнинск, пр-т. К.Маркса, 130',
    partner: 'Тинькофф Банк',
    telephone: '+7(903)948-83-61',
    collection: 'Российское управление инкасации "РОСИНКАС"',
    central: 'Центрального Банка РФ',
    recipient: {
      payeeBank: 'ЗАПАДНО-СИБИРСКОЕ ОТДЕЛЕНИЕ №8647 ПАО "СберБанк России"',
      cell: '9784651274',
      bik: '51419360',
      inn: '7707083893',
      cpp: '440101001',
      cor: {
        title: 'Корр.сч.:',
        value: '301018104000000184031',
      },
    },
  }
}

export const docPreset: IDocPreset = {
  [BankName.SBER]: {
    address:
      'Банк ПАО «Сбербанк»<br/>ул.Вавилова, 19, г.Москва, 117997<br/>Телефон: (4722) 30-50-00, 54-33-06<br/>Факс: (4722) 30-50-00',
    name: 'Банк ПАО «Сбербанк»',
    nameupper: 'СБЕРБАНК',
    nameuppereng: 'SBERBANK',
    namepost: 'СБЕРБАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
            Банк: Филиал №1587 Сбербанка (ПАО) г.Москва<br/>
        БИК: 044525225<br/>
        ИНН: 7707083893<br/>
        Корр.сч.: 301018104000000000225`,
    valueruc: 'Зам. Начальника ОПИО РОО "Москва" филиала №1587 СберБанка (ПАО)',
    footer1: 'Банк Сбербанк (публичное акционерное общество)',
    footer2: 'ОО “Москва” Филиал №1587 Сбербанк (ПАО)',
    footer3: 'ул. Вавилова, 19<br/> г.Москва<br/>117997',
    footer4: 'Телефон: (4722) 30-50-00, 54-33-06<br/>Факс: (4722) 30-50-00',
    footer5: 'S.W.I.F.T.: SBERRUMM<br/>E-mail: info@sb.ru',
    color: '#effff4',
    bik: '044525225',
    inn: '7707083893',
    kpp: '773601001',
    korr: '301018104000000000225',
    img: '/bankdocs/logo/sber.png',
    stamp: '/bankdocs/stamp/sber.png',
  },
  [BankName.TINKOFF]: {
    address:
      'Акционерное общество «Тинькофф Банк»<br/>1-й Волоколамский проезд, 10, г.Москва, 123060<br/>Телефон: (495) 64-81-000<br/>Факс: (495) 645-59-09',
    name: 'Акционерное общество «Тинькофф Банк»',
    nameupper: 'ТИНЬКОФФ',
    nameuppereng: 'TINKOFF',
    namepost: 'ТИНЬКОФФ БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
            Банк: Головной офис АО «Тинькофф Банк»<br/>
          БИК: 044525974<br/>
          ИНН: 7710140679<br/>
          Корр.сч.: 301018100145250000974<br/>
          Расчетный счет(лицевой): 302328101000000000004`,
    valueruc: 'Зам. Начальника ОПИО РОО "Москва" Тинькофф (АО)',
    footer1: 'АО «Тинькофф Банк»',
    footer2: 'Головной офис АО «Тинькофф Банк»',
    footer3: '1-й Волоколамский проезд, д.10, стр.1<br/>г.Москва<br/>123060',
    footer4: 'Телефон: (495) 64-81-000<br/>Факс: (495) 645-59-09',
    footer5: 'S.W.I.F.T.: TICSRUMMXXX<br/>E-mail: cso@tinkoff.ru',
    color: '#fcf1cd',
    bik: '044525974',
    inn: '7710140679',
    kpp: '771301001',
    korr: '301018100145250000974',
    img: '/bankdocs/logo/tinkoff.png',
    stamp: '/bankdocs/stamp/tinkoff.png',
  },
  [BankName.AKBARS]: {
    address:
      '«АК БАРС» БАНК (публичное акционерное общество)<br/>ул.Декабристов, 1, г.Казань, 420066<br/>Телефон: (843) 519-39-99<br/>По РФ: 8 800 2005 303',
    name: '«АК БАРС» БАНК (публичное акционерное общество)',
    nameupper: 'АК БАРС',
    nameuppereng: 'AK BARS',
    namepost: 'АК БАРС БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №1587 «АК БАРС» БАНК (ПАО) г.Казань<br/>
    БИК: 049205805<br/>
    ИНН: 1653001805<br/>
    Корр.сч.: 30101810000000000805<br/>`,
    valueruc: 'Зам. Начальника ОПИО РОО "Казань" филиала №1587 «АК БАРС» БАНК (ПАО)',
    footer1: '«АК БАРС» БАНК (ПАО)',
    footer2: 'Филиал №1587 «АК БАРС» БАНК (ПАО) г.Казань',
    footer3: 'ул. Декабристов, 1<br/>г. Казань<br/> 420066',
    footer4: 'Телефон: (843) 519-39-99<br/>По РФ: 8 800 2005 303',
    footer5: 'S.W.I.F.T.: ARRSRU2K <br/>E-mail: kanc@akbars.ru',
    color: '#fcf1cd',
    bik: '049205805',
    inn: '1653001805',
    kpp: '165601001',
    korr: '30101810000000000805',
    img: '/bankdocs/logo/akbars.png',
    stamp: '/bankdocs/stamp/akbars.png',
  },
  [BankName.ALFA]: {
    address:
      'Альфа-Банк (акционерное общество)<br/>ул.Каланчевская, 27, г.Москва, 107078<br/>Телефон: (495) 974 2515<br/>Факс: (495) 620 9191',
    name: 'Альфа-Банк (акционерное общество)',
    nameupper: 'АЛЬФА-БАНК',
    nameuppereng: 'ALFA BANK',
    namepost: 'АЛЬФА-БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
      Банк: Филиал №1731 Альфа-Банк (АО) г.МОСКВА<br/>
    БИК: 044525593<br/>
    ИНН: 7728168971<br/>
    Корр.сч.: 30101810200000000593<br/>
    Расчетный счет(лицевой): 5536163345000000`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" филиала №1731 Альфа-Банк (АО)',
    footer1: 'Альфа-Банк (АО)',
    footer2: 'Филиал №1731 Альфа-Банк (АО) г.МОСКВА',
    footer3: 'ул.Каланчевская, 27<br/>г.Москва<br/>107078',
    footer4: 'Телефон: (495) 974 2515<br/>Факс: (495) 620 9191',
    footer5: 'S.W.I.F.T.: ALFARUMM<br/>E-mail: mail@alfabank.ru',
    color: '#ffc4c4',
    bik: '044525593',
    inn: '7728168971',
    kpp: '770801001',
    korr: '30101810200000000593',
    img: '/bankdocs/logo/alfa.png',
    stamp: '/bankdocs/stamp/alfa.png',
  },
  [BankName.ATB]: {
    address:
      'Азиатско-Тихоокеанского Банк (акционерное общество)<br/>ул.Амурская, 225, г.Благовещенск, 675000<br/>Телефон: (4162) 220–402<br/>Факс: (4162) 220-400',
    name: 'Азиатско-Тихоокеанского Банк (акционерное общество)',
    nameupper: 'АТБ-БАНК',
    nameuppereng: 'ATB-BANK',
    namepost: 'АТБ-БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №9173 Азиатско-Тихоокеанского Банка (АО)<br/>
      БИК: 041012765<br/>
      ИНН: 2801023444<br/>
      Корр.сч.: 30101810300000000765`,
    valueruc: 'Зам. Начальника ОПиО РОО "Благовещенск" филиала №9173 АТБ-Банк (АО)',
    footer1: 'АТБ-Банк (АО)',
    footer2: 'Филиал №9173 Азиатско-Тихоокеанского Банка (АО)',
    footer3: 'ул. Амурская, д. 225<br/> г.Благовещенск<br/>675000',
    footer4: 'Телефон: (4162) 220–402<br/>Факс: (4162) 220-400',
    footer5: 'S.W.I.F.T.: ASANRU8X <br/>E-mail: atb@atb.su',
    color: '#ffccb8',
    bik: '041012765',
    inn: '2801023444',
    kpp: '280101001',
    korr: '30101810300000000765',
    img: '/bankdocs/logo/atb.png',
    stamp: '/bankdocs/stamp/atb.png',
  },
  [BankName.AVANGARD]: {
    address:
      'АКБ «Авангард» (публичное акционерное общество)<br/>ул.Садовническая, 12, г.Москва, 115035<br/>Телефон: (495) 737-7373<br/>Факс: (495) 956-68-52 ',
    name: 'АКБ «Авангард» (публичное акционерное общество)',
    nameupper: 'АВАНГАРД БАНК',
    nameuppereng: 'AVANGARD BANK',
    namepost: 'АВАНГАРД БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №1587 АКБ «Авангард» (ПАО) г. Москва<br/>
      БИК: 044525201<br/>
      ИНН: 7702021163<br/>
      Корр.сч.: 30101810000000000201`,
    valueruc: 'Зам. Начальника ОПИО РОО "Москва" филиала №1587 АКБ «Авангард» (ПАО)',
    footer1: 'АКБ «Авангард» (ПАО)',
    footer2: 'Филиал №1587 АКБ «Авангард» (ПАО) г. Москва',
    footer3: 'ул. Садовническая, д. 12, стр. 1<br/> г.Москва<br/> 115035',
    footer4: 'Телефон: (495) 737-7373<br/>Факс: (495) 956-68-52 ',
    footer5: 'S.W.I.F.T.: AVJSRUMM<br/>E-mail: info@avangard.ru',
    color: '#e9ebed',
    bik: '044525201',
    inn: '7702021163',
    kpp: '770601001',
    korr: '30101810000000000201',
    img: '/bankdocs/logo/avangard.png',
    stamp: '/bankdocs/stamp/avangard.png',
  },
  [BankName.BSPB]: {
    address:
      'ПАО «Банк «Санкт-Петербург»<br/>пр.Малоохтинский, 64, г.Санкт-Петербург, 195112<br/>Телефон: (812) 329-50-50<br/>По РФ: 8 (800) 222-50-50',
    name: 'ПАО «Банк «Санкт-Петербург»',
    nameupper: 'БСП БАНК',
    nameuppereng: 'BSP BANK',
    namepost: 'БСП БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
          Банк: Головной офис ПАО «Банк «Санкт-Петербург»<br/>
      БИК: 044030790<br/>
      ИНН: 7831000027<br/>
      Корр.сч.: 30101810900000000790`,
    valueruc: 'Зам. Начальника ОПИО РОО "СПБ" Головной офис Банк "Санкт-Петербург" (ПАО)',
    footer1: 'ПАО Банк «Санкт-Петербург» ',
    footer2: 'Головной офис ПАО «Банк «Санкт-Петербург»',
    footer3: 'Малоохтинский пр., д.64<br/>Санкт-Петербург<br/> 195112',
    footer4: 'Телефон: (812) 329-50-50<br/>По РФ: 8 (800) 222-50-50',
    footer5: 'S.W.I.F.T.: JSBSRU2P <br/>E-mail: cc@bspb.ru',
    color: '#f2f7ff',
    bik: '044030790',
    inn: '7831000027',
    kpp: '780601001',
    korr: '30101810900000000790',
    img: '/bankdocs/logo/bspb.png',
    stamp: '/bankdocs/stamp/bspb.png',
  },
  [BankName.CBR]: {
    address:
      'Центральный Банк Российской Федерации<br/>ул.Неглинная, 12, г.Москва, 107016<br/>Телефон: (495) 771-91-00<br/>По РФ: 8 (800) 300-30-00',
    name: 'Центральный Банк Российской Федерации',
    nameupper: 'ЦБ РФ',
    nameuppereng: 'CB RF',
    namepost: 'ЦЕНТРАЛЬНОГО БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №3 ЦБ РФ г. Москва<br/>
      БИК: 044536002<br/>
      ИНН: 7702235133<br/>
      Корр.сч.: 301018104000000000138`,
    valueruc: 'Зам. Начальника ОПИО РОО "Москва" филиала №3 ЦБ РФ',
    footer1: 'Центральный Банк Российской Федерации',
    footer2: 'Филиал №3 ЦБ РФ г. Москва',
    footer3: 'ул. Неглинная, 12<br/> г.Москва<br/>107016',
    footer4: 'Телефон: (495) 771-91-00<br/>По РФ: 8 (800) 300-30-00	',
    footer5: 'S.W.I.F.T.: CBRFRUMM<br/>E-mail: info@cbr.ru',
    color: '#f2f7ff',
    bik: '044536002',
    inn: '7702235133',
    kpp: '770201001',
    korr: '301018104000000000138',
    img: '/bankdocs/logo/cbr.png',
    stamp: '/bankdocs/stamp/cbr.png',
  },
  [BankName.GAZPROM]: {
    address:
      'Газпромбанк (акционерное общество)<br/>ул.Наметкина, 16, к.1, г.Москва, 115184<br/>Телефон: (495) 719-1763<br/>Факс: (495) 913-7319',
    name: 'Газпромбанк (акционерное общество)',
    nameupper: 'ГАЗПРОМБАНК',
    nameuppereng: 'GAZPROMBANK',
    namepost: 'ГАЗПРОМБАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №1934 Газпромбанк (АО) г.МОСКВА<br/>
      БИК: 044525823<br/>
      ИНН: 7744001497<br/>
      Корр.сч.: 301018101000000000214<br/>
      Расчетный счет(лицевой): 55438634935000000`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" филиала №1934 Газпромбанк (ОАО)',
    footer1: 'Газпромбанк (АО)',
    footer2: 'Филиал №1934 Газпромбанк (АО) г.МОСКВА',
    footer3: 'ул. Наметкина, 16, к.1<br/>г.Москва<br/>115184',
    footer4: 'Телефон: (495) 719-1763<br/>Факс: (495) 913-7319',
    footer5: 'S.W.I.F.T.: GAZPRUMM<br/>E-mail: info@gazprombank.ru',
    color: '#b8dfff',
    bik: '044525823',
    inn: '7744001497',
    kpp: '997950001',
    korr: '301018101000000000214',
    img: '/bankdocs/logo/gazprom.png',
    stamp: '/bankdocs/stamp/gazprom.png',
  },
  [BankName.HOMECREDIT]: {
    address:
      'ООО "Хоум Кредит энд Финанс Банк"<br/>ул. Правды, д. 8/1, Москва, 125124<br/>Телефон: (495) 785-82-22<br/>Факс: (495) 785-82-18',
    name: 'ООО "Хоум Кредит энд Финанс Банк"',
    nameupper: 'ХКФ БАНК',
    nameuppereng: 'HCF BANK',
    namepost: 'ХКФ БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №1734 «ХКФ Банк» (ООО)<br/>
      БИК: 044525245<br/>
      ИНН: 7735057951<br/>
      Корр.сч.: 30101810845250000245`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" Филиал ООО «ХКФ Банк»',
    footer1: '«ХКФ Банк» (ООО)',
    footer2: 'Филиал №1734 «ХКФ Банк» (ООО)',
    footer3: 'ул. Правды, д. 8/1<br/>Москва<br/>125124',
    footer4: 'Телефон: (495) 785-82-22<br/>Факс: (495) 785-82-18',
    footer5: 'S.W.I.F.T.: HCFBRUMM <br/>E-mail: info@homecredit.ru',
    color: '#FFCECE',
    bik: '044525245',
    inn: '7735057951',
    kpp: '997950001',
    korr: '30101810845250000245',
    img: '/bankdocs/logo/homecredit.png',
    stamp: '/bankdocs/stamp/homecredit.png',
  },
  [BankName.LOCKOBANK]: {
    address:
      'Коммерческий Банк «ЛОКО-Банк» (акционерное общество)<br/>Ленинградский проспект, дом 39, стр. 80, Москва,  125167<br/>Телефон: (495) 739-5555<br/>По РФ: 8 (800) 250-50-50',
    name: 'Коммерческий Банк «ЛОКО-Банк» (акционерное общество)',
    nameupper: 'ЛОКО БАНК',
    nameuppereng: 'LOKO BANK',
    namepost: 'ЛОКО БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №1734 «Локо-Банк» (АО)<br/>
      БИК: 044525161<br/>
      ИНН: 7750003943<br/>
      Корр.сч.: 30101810945250000161`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" Филиал АО «Локо-Банк»',
    footer1: 'Локо-Банк (АО)',
    footer2: 'Филиал №1734 «Локо-Банк» (АО)',
    footer3: 'Ленинградский проспект, дом 39, стр. 80<br/>Москва<br/> 125167',
    footer4: 'Телефон: (495) 739-5555<br/>По РФ: 8 (800) 250-50-50',
    footer5: 'S.W.I.F.T.: CLOKRUMM <br/>E-mail: info@lockobank.ru',
    color: '#ebf1f5',
    bik: '044525161',
    inn: '7750003943',
    kpp: '775001001',
    korr: '30101810945250000161',
    img: '/bankdocs/logo/lockobank.png',
    stamp: '/bankdocs/stamp/lockobank.png',
  },
  [BankName.MINBANK]: {
    address:
      'ПАО «Московский Индустриальный банк»<br/>ул. Орджоникидзе, д. 5, Москва,  115419<br/>Телефон: (495) 74-000-74<br/>По РФ: 8 (800) 100-74-74',
    name: 'ПАО «Московский Индустриальный банк»',
    nameupper: 'МИН БАНК',
    nameuppereng: 'MIN BANK',
    namepost: 'МИН БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №1734 «МИнБанк» (ПАО)<br/>
      БИК: 044525600<br/>
      ИНН: 7725039953<br/>
      Корр.сч.: 30101810300000000600`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" Филиал ПАО «МИнБанк»',
    footer1: 'МИнБанк (ПАО)',
    footer2: 'Филиал №1734 «МИнБанк» (ПАО)',
    footer3: 'ул. Орджоникидзе, д. 5<br/>Москва<br/> 115419',
    footer4: 'Телефон: (495) 74-000-74<br/>По РФ: 8 (800) 100-74-74',
    footer5: 'S.W.I.F.T.: MINNRUMM <br/>E-mail: info@minbank.ru',
    color: '#ffb3c5',
    bik: '044525600',
    inn: '7725039953',
    kpp: '997950001',
    korr: '30101810300000000600',
    img: '/bankdocs/logo/minbank.png',
    stamp: '/bankdocs/stamp/minbank.png',
  },
  [BankName.MKB]: {
    address:
      'ПАО «Московский Кредитный Банк»<br/>Луков переулок, д. 2, стр. 1, Москва,  107045<br/>Телефон: (495) 777-4-888<br/>По РФ: 8 (800) 100-4-888',
    name: 'ПАО «Московский Кредитный Банк»',
    nameupper: 'МКБ БАНК',
    nameuppereng: 'MKB BANK',
    namepost: 'МКБ БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №1734 «МКБ» (ПАО)<br/>
      БИК: 044525659<br/>
      ИНН: 7734202860<br/>
      Корр.сч.: 30101810745250000659`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" Филиал ПАО «МКБ»',
    footer1: 'МКБ (ПАО)',
    footer2: 'Филиал №1734 «МКБ» (ПАО)',
    footer3: 'Луков переулок, д. 2, стр. 1<br/>Москва<br/> 107045',
    footer4: 'Телефон: (495) 777-4-888<br/>По РФ: 8 (800) 100-4-888',
    footer5: 'S.W.I.F.T.: MCRBRUMM <br/>E-mail: info@mkbb.ru',
    color: '#ffb3c5',
    bik: '044525659',
    inn: '7734202860',
    kpp: '770801001',
    korr: '30101810745250000659',
    img: '/bankdocs/logo/mkb.png',
    stamp: '/bankdocs/stamp/mkb.png',
  },
  [BankName.MTS]: {
    address:
      'Публичное акционерное общество "МТС-Банк"<br/>Андропова пр-т, д. 18, корп.1, г.Москва, 115432<br/>Телефон: (495) 739-29-81<br/>Факс: (495) 745-98-52',
    name: 'Публичное акционерное общество "МТС-Банк"',
    nameupper: 'МТС БАНК',
    nameuppereng: 'MTS BANK',
    namepost: 'МТС БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №07331 "МТС-Банк" (ПАО)<br/>
      БИК: 044525232<br/>
      ИНН: 7702045051<br/>
      Корр.сч.: 30101810600000000232`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" филиала №07331 МТС-Банк (ПАО)',
    footer1: 'МТС-Банк (ПАО)',
    footer2: 'Филиал №07331 «МТС-Банк» (ПАО)',
    footer3: 'Андропова пр-т, д. 18, корп.1<br/>г.Москва<br/>115432',
    footer4: 'Телефон: (495) 739-29-81<br/>Факс: (495) 745-98-52',
    footer5: 'S.W.I.F.T.: MBRDRUMM <br/>E-mail: info@mtsbank.ru',
    color: '#ff7078',
    bik: '044525232',
    inn: '7702045051',
    kpp: '770901001',
    korr: '30101810600000000232',
    img: '/bankdocs/logo/mts.png',
    stamp: '/bankdocs/stamp/mts.png',
  },
  [BankName.OPEN]: {
    address:
      'ПАО Банк "ФК Открытие"<br/>ул. Спартаковская, дом 5/1, г.Москва,  105066<br/>Телефон: (495) 224-44-00<br/>По РФ: 8 800 444 44 00',
    name: 'ПАО Банк "ФК Открытие"',
    nameupper: 'БАНК ОТКРЫТИЕ',
    nameuppereng: 'BANK OTKRYTIYE',
    namepost: 'БАНКА ОТКРЫТИЕ',
    recs: `<b>Реквизиты банка:</b><br/>
          Банк: Филиал №1591 Банк "ФК Открытие" (ПАО)<br/>
      БИК: 044525297<br/>
      ИНН: 7706092528<br/>
      Корр.сч.: 30101810945250000297`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" Филиал Центральный Банк "ФК Открытие"',
    footer1: 'ФК Открытие (ПАО)',
    footer2: 'Филиал №1591 Банк «ФК Открытие» (ПАО)',
    footer3: 'ул. Спартаковская, дом 5/1<br/>г.Москва<br/> 105066',
    footer4: 'Телефон: (495) 224-44-00<br/>По РФ: 8 800 444 44 00',
    footer5: 'S.W.I.F.T.: JSNMRUMMXXX <br/>E-mail: info@open.ru',
    color: '#95CFE7',
    bik: '044525297',
    inn: '7706092528',
    kpp: '770501001',
    korr: '30101810945250000297',
    img: '/bankdocs/logo/open.png',
    stamp: '/bankdocs/stamp/open.png',
  },
  [BankName.POCHTA]: {
    address:
      'Почта Банк (акционерное общество)<br/>ул.Пятницкая, 54, стр.2, г.Москва, 115184<br/>Телефон: (499) 550-07-70<br/>Факс: (4722) 550-07-70',
    name: 'Почта Банк (акционерное общество)',
    nameupper: 'ПОЧТА БАНК',
    nameuppereng: 'POCHTA BANK',
    namepost: 'ПОЧТА БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №847 Почта Банк (АО) г.МОСКВА<br/>
      БИК: 042007738<br/>
      ИНН: 3232005484<br/>
      Корр.сч.: 301018101000000000214<br/>
      Расчетный счет(лицевой): 55438634935000000`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" филиала №847 Почта Банк (ПАО)',
    footer1: 'Почта Банк (АО)',
    footer2: 'Филиал №847 Почта Банк (АО) г.МОСКВА',
    footer3: 'ул.Пятницкая, 54, стр.2<br/>г.Москва<br/>115184',
    footer4: 'Телефон: (499) 550-07-70<br/>Факс: (4722) 550-07-70',
    footer5: 'S.W.I.F.T.: POSBRUMM<br/>E-mail: pr@pochtabank.ru',
    color: '#D3CEFC',
    bik: '042007738',
    inn: '3232005484',
    kpp: '771801001',
    korr: '301018101000000000214',
    img: '/bankdocs/logo/pochta.png',
    stamp: '/bankdocs/stamp/pochta.png',
  },
  [BankName.PSBANK]: {
    address:
      'Публичное акционерное общество «Промсвязьбанк»<br/>ул. Неглинная, 12, Москва,  107016<br/>Телефон: (495) 787-33-33<br/>По РФ: 8 (800) 333-03-03',
    name: 'Публичное акционерное общество «Промсвязьбанк»',
    nameupper: 'ПСБ БАНК',
    nameuppereng: 'PSB BANK',
    namepost: 'ПСБ БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
          Банк: Филиал №1734 «Промсвязьбанк» (ПАО)<br/>
        БИК: 044525555<br/>
        ИНН: 7744000912<br/>
        Корр.сч.: 30101810400000000555`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" Филиал ПАО «Промсвязьбанк»',
    footer1: 'Промсвязьбанк (ПАО)',
    footer2: 'Филиал №1734 «Промсвязьбанк» (ПАО)',
    footer3: 'ул. Неглинная, 12<br/>Москва<br/> 107016',
    footer4: 'Телефон: (495) 787-33-33<br/>По РФ: 8 (800) 333-03-03',
    footer5: 'S.W.I.F.T.: PRMSRUMM <br/>E-mail: info@psbank.ru',
    color: '#8a8ab5',
    bik: '044525555',
    inn: '7744000912',
    kpp: '772201001',
    korr: '30101810400000000555',
    img: '/bankdocs/logo/psbank.png',
    stamp: '/bankdocs/stamp/psbank.png',
  },
  [BankName.RAIFFEISEN]: {
    address:
      'Акционерное общество «Райффайзенбанк»<br/>ул.Троицкая, д.17, стр. 1, Москва,  129090<br/>Телефон: (495) 777-17-17<br/>По РФ: 8 (800) 700-91-00',
    name: 'Акционерное общество «Райффайзенбанк»',
    nameupper: 'РАЙФФАЙЗЕН БАНК',
    nameuppereng: 'RAIFFEISEN BANK',
    namepost: 'РАЙФФАЙЗЕН БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: АО «Райффайзенбанк»<br/>
      БИК: 044525700<br/>
      ИНН: 7744000302<br/>
      Корр.сч.: 30101810200000000700`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" АО «Райффайзенбанк»',
    footer1: 'АО «Райффайзенбанк»',
    footer2: 'АО «Райффайзенбанк»',
    footer3: 'ул.Троицкая, д.17, стр. 1<br/>Москва<br/> 129090',
    footer4: 'Телефон: (495) 777-17-17<br/>По РФ: 8 (800) 700-91-00',
    footer5: 'S.W.I.F.T.: RZBMRUMM <br/>E-mail: info@raiffeisen.ru',
    color: '#fff675',
    bik: '044525700',
    inn: '7744000302',
    kpp: '770201001',
    korr: '30101810200000000700',
    img: '/bankdocs/logo/raiffeisen.png',
    stamp: '/bankdocs/stamp/raiffeisen.png',
  },
  [BankName.RNCB]: {
    address:
      'ПАО «Российский Национальный Коммерческий Банк»<br/>ул. Набережная им.60-летия СССР, д. 32, Симферополь, 295000<br/>Телефон: (3652) 550-500<br/>7 (495) (495) 232-90-00',
    name: 'ПАО «Российский Национальный Коммерческий Банк»',
    nameupper: 'РНКБ БАНК',
    nameuppereng: 'RNKB BANK',
    namepost: 'РНКБ БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №1734 «РНКБ» (ПАО)<br/>
      БИК: 043510607<br/>
      ИНН: 7701105460<br/>
      Корр.сч.: 30101810335100000607`,
    valueruc: 'Зам. Начальника ОПиО РОО "Симферополь" Филиал ПАО «РНКБ»',
    footer1: 'РНКБ (ПАО)',
    footer2: 'Филиал №1734 «РНКБ» (ПАО)',
    footer3: 'ул. Набережная им.60-летия СССР, д. 32<br/>Симферополь<br/>295000',
    footer4: 'Телефон: (3652) 550-500<br/>7 (495) (495) 232-90-00',
    footer5: 'S.W.I.F.T.: RNCORUMM <br/>E-mail: info@rncb.ru',
    color: '#9efffe',
    bik: '043510607',
    inn: '7701105460',
    kpp: '910201001',
    korr: '30101810335100000607',
    img: '/bankdocs/logo/rncb.png',
    stamp: '/bankdocs/stamp/rncb.png',
  },
  [BankName.ROSBANK]: {
    address:
      'Публичное Акционерное Общество «РосБанк»<br/>ул.Маши Порываевой, 34, Москва,  107078<br/>Телефон: 8 (800) 200-54-34',
    name: 'Публичное Акционерное Общество «РосБанк»',
    nameupper: 'РОСБАНК',
    nameuppereng: 'ROSBANK',
    namepost: 'РОСБАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №1734 «РосБанк» (ПАО)<br/>
      БИК: 044525256<br/>
      ИНН: 7730060164<br/>
      Корр.сч.: 30101810000000000256`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" Филиал ПАО «РосБанк»',
    footer1: 'РосБанк (ПАО)',
    footer2: 'Филиал №1734 «РосБанк» (ПАО)',
    footer3: 'ул.Маши Порываевой, 34<br/>Москва<br/> 107078',
    footer4: 'Телефон: 8 (800) 200-54-34',
    footer5: 'S.W.I.F.T.: RSBNRUMM<br/>E-mail: info@rosbank.ru',
    color: '#e37888',
    bik: '044525256',
    inn: '7730060164',
    kpp: '770801001',
    korr: '30101810000000000256',
    img: '/bankdocs/logo/rosbank.png',
    stamp: '/bankdocs/stamp/rosbank.png',
  },
  [BankName.RSB]: {
    address:
      'Акционерное общество «Банк Русский Стандарт»<br/>ул. Ткацкая, д. 36, г.Москва, 105187<br/>Телефон: (495) 787 84 40<br/>Факс: (495) 797 84 02',
    name: 'Акционерное общество «Банк Русский Стандарт»',
    nameupper: 'РСБАНК',
    nameuppereng: 'RSBANK',
    namepost: 'РСБАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
          Банк: Филиал №71931 Банка Русский Стандарт (ЗАО)<br/>
        БИК: 044525151<br/>
        ИНН: 7707056547<br/>
        Корр.сч.: 30101810845250000151<br/>
        Расчетный счет(лицевой): 5536733815000000`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" филиала №71831 Банк Русский Стандарт (ЗАО)',
    footer1: 'Банк Русский Стандарт (ЗАО)',
    footer2: 'Филиал №71931 Банка Русский Стандарт (ЗАО)',
    footer3: 'ул. Ткацкая, д. 36<br/>г.Москва<br/>105187',
    footer4: 'Телефон: (495) 787 84 40<br/>Факс: (495) 797 84 02',
    footer5: 'S.W.I.F.T.: RSJSRUMM <br/>E-mail: mail@rsb.ru',
    color: '#919191',
    bik: '044525151',
    inn: '7707056547',
    kpp: '771901001',
    korr: '30101810845250000151',
    img: '/bankdocs/logo/rsb.png',
    stamp: '/bankdocs/stamp/rsb.png',
  },
  [BankName.RSHB]: {
    address:
      'Акционерное общество «Российский Сельскохозяйственный банк»<br/> ул. Неглинная, 12, г.Москва,  107016<br/>Телефон: (499) 300-30-00<br/>По РФ: 8 (800) 100-0-100',
    name: 'Акционерное общество «Российский Сельскохозяйственный банк»',
    nameupper: 'РСХ БАНК',
    nameuppereng: 'RSH BANK',
    namepost: 'РСХ БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
          Банк: Филиал №1843 «Россельхозбанк» (АО)<br/>
        БИК: 044525111<br/>
        ИНН: 7725114488<br/>
        Корр.сч.: 30101810200000000111`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" Филиал АО «Россельхозбанк»',
    footer1: 'Россельхозбанк (АО)',
    footer2: 'Филиал №1843 «Россельхозбанк» (АО)',
    footer3: 'ул. Неглинная, 12<br/>г.Москва<br/> 107016',
    footer4: 'Телефон: (499) 300-30-00<br/>По РФ: 8 (800) 100-0-100',
    footer5: 'S.W.I.F.T.: RUAGRUMM <br/>E-mail: info@rnkb.ru',
    color: '#81cc84',
    bik: '044525111',
    inn: '7725114488',
    kpp: '997950001',
    korr: '30101810200000000111',
    img: '/bankdocs/logo/rshb.png',
    stamp: '/bankdocs/stamp/rshb.png',
  },
  [BankName.SOVCOMBANK]: {
    address:
      'Публичное акционерное общество «Совкомбанк»<br/>пр. Текстильщиков, д. 46, Кострома,  156000<br/>Телефон: (495) 988-00-00<br/>По РФ: 8 (800) 200-66-96',
    name: 'Публичное акционерное общество «Совкомбанк»',
    nameupper: 'СОВКОМБАНК',
    nameuppereng: 'SOVCOMBANK',
    namepost: 'СОВКОМБАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
          Банк: Филиал №1734 «Совкомбанк» (ПАО)<br/>
        БИК: 043469743<br/>
        ИНН: 4401116480<br/>
        Корр.сч.: 30101810300000000743`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" Филиал ПАО «Совкомбанк»',
    footer1: 'Совкомбанк (ПАО)',
    footer2: 'Филиал №1734 «Совкомбанк» (ПАО)',
    footer3: 'пр. Текстильщиков, д. 46<br/>Кострома<br/> 156000',
    footer4: 'Телефон: (495) 988-00-00<br/>По РФ: 8 (800) 200-66-96',
    footer5: 'S.W.I.F.T.: SOMRRUMM <br/>E-mail: info@sovcombank.ru',
    color: '#8793c9',
    bik: '043469743',
    inn: '4401116480',
    kpp: '770343003',
    korr: '30101810300000000743',
    img: '/bankdocs/logo/sovcombank.png',
    stamp: '/bankdocs/stamp/sovcombank.png',
  },
  [BankName.UBRR]: {
    address:
      'ПАО «Уральский банк реконструкции и развития»<br/>ул. Сакко и Ванцетти, 67, Екатеринбург,  620014<br/>Телефон: (343) 376-49-50<br/>По РФ: 8 (800) 1000-200',
    name: 'ПАО «Уральский банк реконструкции и развития»',
    nameupper: 'УБРИР БАНК',
    nameuppereng: 'UBRIR BANK',
    namepost: 'УБРИР БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
          Банк: Филиал №1734 «УБРиР» (ПАО)<br/>
      БИК: 046577795<br/>
      ИНН: 6608008004<br/>
      Корр.сч.: 30101810900000000795`,
    valueruc: 'Зам. Начальника ОПиО РОО "Екатеренбург" Филиал ПАО «УБРиР»',
    footer1: 'УБРиР (ПАО)',
    footer2: 'Филиал №1734 «УБРиР» (ПАО)',
    footer3: 'ул. Сакко и Ванцетти, 67<br/>Екатеринбург<br/> 620014',
    footer4: 'Телефон: (343) 376-49-50<br/>По РФ: 8 (800) 1000-200',
    footer5: 'S.W.I.F.T.: UBRDRU4E <br/>E-mail: info@ubrr.ru',
    color: '#f792bc',
    bik: '046577795',
    inn: '6608008004',
    kpp: '667101001',
    korr: '30101810900000000795',
    img: '/bankdocs/logo/ubrr.png',
    stamp: '/bankdocs/stamp/ubrr.png',
  },
  [BankName.UNICREDIT]: {
    address:
      'Акционерное общество "ЮниКредит Банк"<br/>Пречистенская наб., д.9, г.Москва, 119034<br/>Телефон: (495) 258-72-00<br/>Факс: (495) 956-15-24',
    name: 'Акционерное общество "ЮниКредит Банк"',
    nameupper: 'ЮНИКРЕДИТ БАНК',
    nameuppereng: 'UNICREDIT BANK',
    namepost: 'ЮНИКРЕДИТ БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Головной офис АО "ЮниКредит Банк"<br/>
      БИК: 044525545<br/>
      ИНН: 7710030411<br/>
      Корр.сч.: 30101810300000000545`,
    valueruc: 'Зам. Начальника ОПИО РОО "Москва" Головной офис АО "ЮниКредит Банк"',
    footer1: 'АО «ЮниКредит Банк»',
    footer2: 'Головной офис АО «ЮниКредит Банк»',
    footer3: 'Пречистенская наб., д.9<br/>г.Москва<br/> 119034',
    footer4: 'Телефон: (495) 258-72-00<br/>Факс: (495) 956-15-24',
    footer5: 'S.W.I.F.T.: IMBKRUMM <br/>E-mail: unicredit@unicredit.ru',
    color: '#ff969f',
    bik: '044525545',
    inn: '6608008004',
    kpp: '770401001',
    korr: '30101810300000000545',
    img: '/bankdocs/logo/unicredit.png',
    stamp: '/bankdocs/stamp/unicredit.png',
  },
  [BankName.URALSIB]: {
    address:
      'Публичное акционерное общество «БАНК УРАЛСИБ»<br/>ул. Ефремова, д.8, г.Москва, 119048<br/>Телефон: (4855) 29-17-21<br/>По РФ: 8 (800) 250-57-57',
    name: 'Публичное акционерное общество «БАНК УРАЛСИБ»',
    nameupper: 'УРАЛСИБ БАНК',
    nameuppereng: 'URALSIB BANK',
    namepost: 'УРАЛСИБ БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №1914 «БАНК УРАЛСИБ» (ПАО)<br/>
      БИК: 044525787<br/>
      ИНН: 0274062111<br/>
      Корр.сч.: 30101810100000000787`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" Филиал ПАО «Банк Уралсиб»',
    footer1: 'ПАО «БАНК УРАЛСИБ»',
    footer2: 'Филиал №1914 «БАНК УРАЛСИБ»',
    footer3: 'ул. Ефремова, д.8<br/>г.Москва<br/> 119048',
    footer4: 'Телефон: (4855) 29-17-21<br/>По РФ: 8 (800) 250-57-57',
    footer5: 'S.W.I.F.T.: AVTBRUMM<br/>E-mail: info@uralsib.ru',
    color: '#84a4d1',
    bik: '044525787',
    inn: '0274062111',
    kpp: '770401001',
    korr: '30101810100000000787',
    img: '/bankdocs/logo/uralsib.png',
    stamp: '/bankdocs/stamp/uralsib.png',
  },
  [BankName.VTB]: {
    address:
      'Банк ВТБ (Публичное акционерное общество)<br/>пер.Дегтярный, д.11, лит.А, г.Санкт-Петербург, 191144<br/>Телефон: (4952) 584-601<br/>Факс: (4952) 584-601',
    name: 'Банк ВТБ (Публичное акционерное общество)',
    nameupper: 'ВТБ БАНК',
    nameuppereng: 'VTB BANK',
    namepost: 'ВТБ БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Филиал №1588 ВТБ (ПАО) г.МОСКВА<br/>
      БИК: 042007738<br/>
      ИНН: 7710353606<br/>
      Корр.сч.: 301018101000000000738`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" филиала №1588 ВТБ (ПАО)',
    footer1: 'ВТБ (ПАО)',
    footer2: 'Филиал №1588 ВТБ (ПАО) г.МОСКВА',
    footer3: 'пер.Дегтярный, д.11, лит.А<br/>г.Санкт-Петербург<br/>191144',
    footer4: 'Телефон: (4952) 584-601<br/>Факс: (4952) 584-601',
    footer5: 'S.W.I.F.T.: VTBRRUMM<br/>E-mail: info@vtb.ru',
    color: '#D3E3FF',
    bik: '042007738',
    inn: '7710353606',
    kpp: '997950001',
    korr: '301018101000000000738',
    img: '/bankdocs/logo/vtb.png',
    stamp: '/bankdocs/stamp/vtb.png',
  },
  [BankName.ABSOLUT]: {
    address:
      'Акционерный коммерческий банк "Абсолют Банк"(ПАО)<br/>Цветной бульвар 18, г.Москва, 127051<br/>Телефон: (495) 777-71-71<br/>Факс: (495) 777-71-71',
    name: 'Акционерный коммерческий банк "Абсолют Банк" (ПАО)',
    nameupper: 'АБСОЛЮТ БАНК',
    nameuppereng: 'ABSOLUT BANK',
    namepost: 'АБСОЛЮТ БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Головной офис АКБ «Абсолют Банк» (ПАО)<br/>
      БИК: 044525976<br/>
      ИНН: 7736046991<br/>
      Корр.сч.: 30101810500000000976`,
    valueruc: 'Зам. Начальника ОПиО РОО "Москва" Головной офис АКБ "Абсолют Банк" (ПАО)',
    footer1: 'АКБ «Абсолют Банк» (ПАО)',
    footer2: 'Головной офис АКБ «Абсолют Банк» (ПАО)',
    footer3: 'Цветной бульвар 18<br/>г.Москва<br/>127051',
    footer4: 'Телефон: (495) 777-71-71<br/>Факс: (495) 777-71-71',
    footer5: 'S.W.I.F.T.: ABSLRUMM<br/>E-mail: info@absolutbank.ru',
    color: '#ffc6b5',
    bik: '044525976',
    inn: '7736046991',
    kpp: '770201001',
    korr: '30101810500000000976',
    img: '/bankdocs/logo/absolut.png',
    stamp: '/bankdocs/stamp/absolut.png',
  },
  [BankName.HLINOV]: {
    address:
      'Коммерческий Банк «Хлынов» (Акционерное Общество)<br/>ул. Урицкого, 40, г.Киров, 610002<br/>Телефон: (8332) 252-104<br/>Факс: (8332) 252-504',
    name: 'Коммерческий Банк «Хлынов» (Акционерное Общество)',
    nameupper: 'ХЛЫНОВ БАНК',
    nameuppereng: 'HLYNOV BANK',
    namepost: 'ХЛЫНОВ БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Центральный офис АО КБ «Хлынов»<br/>
      БИК: 043304711<br/>
      ИНН: 4346013603<br/>
      Корр.сч.: 30101810100000000711`,
    valueruc: 'Зам. Начальника ОПиО РОО "Киров" Центральный офис АО КБ «Хлынов»',
    footer1: 'АО КБ «Хлынов»',
    footer2: 'Центральный офис АО КБ «Хлынов»',
    footer3: 'ул. Урицкого, 40<br/>г.Киров<br/>610002',
    footer4: 'Телефон: (8332) 252-104<br/>Факс: (8332) 252-504',
    footer5: 'S.W.I.F.T.: KHOARU22<br/>E-mail: bank@bank-hlynov.ru',
    color: '#e9ebed',
    bik: '043304711',
    inn: '7736046991',
    kpp: '434501001',
    korr: '30101810100000000711',
    img: '/bankdocs/logo/hlinov.png',
    stamp: '/bankdocs/stamp/hlinov.png',
  },
  [BankName.NBRB]: {
    address:
      'Национальный Банк Республики Беларусь<br/>пр. Независимости 20, Минск 220008, Беларусь<br/>Телефон: +375 17 375-20-02',
    name: 'Национальный Банк Республики Беларусь',
    nameupper: 'НБ РБ',
    nameuppereng: 'NB RB',
    namepost: 'НАЦИОНАЛЬНОГО БАНКА',
    recs: `<b>Реквизиты банка:</b><br/>
        Банк: Национальный Банк Республики Беларусь<br/>
        БИК: 245001292<br/>
        ИНН: 100665467<br/>
        Корр.сч.: 30107810900000000134`,
    valueruc: 'Зам. Начальника ОПиО РОО "Минск" НБ РБ',
    footer1: 'Национальный Банк Республики Беларусь',
    footer2: 'Филиал №1 НБ РБ г. Минск',
    footer3: 'пр. Независимости 20,<br/> Минск 220008,<br/> Беларусь',
    footer4: 'Телефон: +375 17 375-20-02',
    footer5: 'S.W.I.F.T.: NBRBBY2XXXX <br/>E-mail: info@nbrb.by',
    color: '#8f2041',
    bik: '245001292',
    inn: '100665467',
    kpp: '997950001',
    korr: '30107810900000000134',
    img: '/bankdocs/logo/nbrb.png',
    stamp: '/bankdocs/stamp/nbrb.png',
  },
  [BankName.FSSP]: {
    address:
      'Федеральная служба судебных приставов<br/>Гольяновская ул., 4А, Москва, 105094<br/>Телефон: +7 499 558-03-66',
    name: 'Федеральная служба судебных приставов',
    nameupper: 'ФССП',
    nameuppereng: 'FSSP',
    namepost: 'ФССП',
    recs: `<b>Реквизиты банка:</b><br/>
        Федеральная служба судебных приставов (ФССП России)<br/>
        ИНН: 7709576929<br/>
        КПП: 770201001<br/>
        ОГРН: 1047796859791`,
    valueruc: 'Зам. Начальника ОПиО РОО ФССП',
    footer1: 'Федеральная служба судебных приставов',
    footer2: 'Главное управление (ФССП России)',
    footer3: 'Гольяновская ул., 4А, Москва, 105094',
    footer4: 'Телефон: +7 499 558-03-66',
    footer5: 'E-mail: mail@r77.fssp.gov.ru',
    color: '#f2f7ff',
    bik: '-',
    inn: '7709576929',
    kpp: '770201001',
    korr: '-',
    img: '/bankdocs/logo/nbrb.png',
    stamp: '/bankdocs/stamp/nbrb.png',
  },
}
