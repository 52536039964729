import { createSlice, Draft } from '@reduxjs/toolkit'
import { ILink } from '../../dto/links'

export interface ILinks {
  linksList: ILink[]
}

const initialState: ILinks = {
  linksList: [],
}

export const linksSlice = createSlice({
  name: 'links',
  initialState,
  reducers: {
    setLinks: (state: Draft<ILinks>, { payload }) => {
      state.linksList = payload
    },
  },
})

export const { setLinks } = linksSlice.actions

export default linksSlice.reducer
