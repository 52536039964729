import React from 'react'
import styles from './CustomManagerSelect.module.scss'
import cn from 'classnames'

interface IData {
  value: string
  title: string
}

type CustomFormItemProps = {
  className?: string
  height?: number
  value?: number
  onChange?: (count: any) => void
  data: IData[]
}

const CustomManagerSelect: React.FC<CustomFormItemProps> = ({
  data,
  value,
  onChange,
  className,
}) => {
  const change = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    const value = Array.from(e.target.selectedOptions, (option: any) => option?.value)
    onChange && onChange(value)
  }
  return (
    <div className={cn(styles.select, className)}>
      <select multiple value={value} onChange={change}>
        {data.map((el) => (
          <option value={el.value} key={el.value}>
            {el.title}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CustomManagerSelect
