import { Editor } from '@tinymce/tinymce-react'
import React from 'react'
import { useElementsContext } from '../../../../../../context/elementsContext'
import { SliderUi } from '../../../../../../../../components/ui/SliderUi/SliderUi'
import cls from '../LogoEdit/LogoEdit.module.scss'
import { IElem } from '../../../../../../../DocTemplates/templates.interface'

export const tinyApiKey = '75e097c5d1472bd795d0e495c0a6688d36adf3f222bf04ae7e2badc76da39004'

interface TinyProps {
  element: IElem
}

export const Tiny: React.FC<TinyProps> = ({ element }) => {
  const { setCurrentItem } = useElementsContext()
  const textUpdate = (text: string) => {
    element &&
      setCurrentItem((prev) => {
        const filtered = prev.template.filter((el) => el.id !== element.id)

        return {
          ...prev,
          template: [
            ...filtered,
            {
              ...element,
              value: text,
            },
          ],
        }
      })
  }

  const imgUpdate = (val: string) => {
    setCurrentItem((prev) => {
      const filtered = prev.template.filter((el) => el.id !== element.id)
      return {
        ...prev,
        template: [
          ...filtered,
          {
            ...element,
            width: Number(val),
          },
        ],
      }
    })
  }
  return (
    <div className={cls.ImgEdit}>
      <SliderUi
        label='Ширина:'
        min={2}
        max={100}
        onChange={(e) => imgUpdate(String(e))}
        value={element.width ?? 10}
      />
      <Editor
        apiKey={tinyApiKey}
        // tinymceScriptSrc={`https://cdn.tiny.cloud/1/${tinyApiKey}/tinymce/6/tinymce.min.js`}
        value={element?.value}
        onEditorChange={(e) => {
          textUpdate(e)
          // setValue({ ...value, bodyText: e })
        }}
        init={{
          height: 350,
          width: 510,
          fontFormats:
            'Open Sans=open sans; Roboto=roboto; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
          contentStyle:
            "@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap'); body { font-family: Times New Roman; font-size:12px; line-height:1; }",
          menubar: false,
          plugins: [
            'lineheight',
            'image',
            'table',
            'fontsizeselect',
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'lineheight | fontsizeselect | fontselect | image |' +
            'bold italic backcolor forecolor underline strikethrough' +
            '| alignleft aligncenter alignright alignjustify |' +
            'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
          font_size_input_defaultUnit: 'px',
          fontsize_formats:
            '4px 5px 6px 8px 10px 11px 12px 14px 16px 18px 20px 24px 28px 30px 32px 36px',
          font_formats:
            'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica;PT Sans Narrow=PT Sans Narrow; Oswald=oswald; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;',
          content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap'); body { font-family: Times New Roman; font-size:12px; line-height:1; } p{line-height:1;}",
          line_height_formats: '1 1.2 1.3 1.4 1.6 2',
        }}
      />
    </div>
  )
}
