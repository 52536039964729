import React, { DetailedHTMLProps, ReactNode } from 'react'
import styles from './Title.Ui.module.scss'
import cn from 'classnames'

interface Props extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode | string
  big?: boolean
  colored?: boolean
}

const TitleUi: React.FC<Props> = ({ children, className, big, colored = true }) => {
  return (
    <div
      className={cn(styles.container, className, { [styles.big]: big, [styles.colored]: colored })}
    >
      {children}
    </div>
  )
}

export default TitleUi
