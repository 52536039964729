import React, { useEffect, useMemo, useState } from 'react'
import cls from './PermissionsTemplatesPage.module.scss'
import BoxUi from '../../components/ui/BoxUi/BoxUi'
import { AxiosResponse } from 'axios'
import axiosInstance from '../../config/axios'
import { serverPath } from '../../constants/api-endpoints'
import ButtonUi from '../../components/ui/ButtonUi/ButtonUi'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
import TitleUi from '../../components/ui/TitleUi/TilteUi'
import { useModalContext } from '../../context/modalContext'
import { AddPermissionTemplate } from './components/AddPermissionTemplate/AddPermissionTemplate'
import { IUserPermissionTemplate } from '../../dto/userPermissions.dto'
import CustomTable from '../../components/ui/CustomTable/CustomTable'
import { columnsPermissionsTemplatesPage } from './PermissionsTemplatesPage.columns'
import DeleteModal from '../../components/Modals/DeleteModal/DeleteModal'
import { useMessage } from '../../hooks/useMessage'
import { errorMapper } from '../../utils/errorMapper'
import { ErrorMessages } from '../../constants/errors'
import { EditPermissionTemplate } from './components/EditPermissionTemplate/EditPermissionTemplate'

export const PermissionsTemplatesPage: React.FC = () => {
  const { setModalComponent } = useModalContext()
  const { error, success } = useMessage()

  const [data, setData] = useState<IUserPermissionTemplate[]>()
  const getPermissionsTemplates = async () => {
    const { data }: AxiosResponse<IUserPermissionTemplate[]> = await axiosInstance.get(
      serverPath.PERMISSIONS_TEMPLATES,
    )
    setData(data)
  }

  useEffect(() => {
    getPermissionsTemplates()
  }, [])

  const addHandler = () => {
    setModalComponent({ component: <AddPermissionTemplate refresh={getPermissionsTemplates} /> })
  }

  const mappedData = useMemo(() => {
    if (data && data.length > 0) {
      return data.map((el) => ({
        ...el,
        key: el.id,
        permissions: el.permissions.length,
        users: el.users.length,
      }))
    }
    return []
  }, [data])

  const deleteHandler = (id: string) => {
    setModalComponent({
      component: (
        <DeleteModal
          title='Вы действительно хотите удалить эту группу разрешений?'
          handler={async () => {
            try {
              await axiosInstance.delete(serverPath.PERMISSIONS_TEMPLATES + '/' + id)
              success('Группа разрешений успешно удалена')
              getPermissionsTemplates()
              setModalComponent({ component: null })
            } catch (e) {
              error(errorMapper(ErrorMessages.REMOVE_DATA, e), 'Ошибка удаления группы разрешений')
            }
          }}
        />
      ),
    })
  }

  const editHandler = (id: string) => {
    const current = data?.find((el) => el.id === id)
    current &&
      setModalComponent({
        component: <EditPermissionTemplate current={current} refresh={getPermissionsTemplates} />,
      })
  }

  return (
    <BoxUi className={cls.permissionsTemplatesPage}>
      <div className={cls.header}>
        <TitleUi colored={false}>Группы доступа</TitleUi>
        <ButtonUi color='green' onClick={addHandler}>
          <PlusCircleOutlined />
          Добавить группу
        </ButtonUi>
      </div>
      <Divider />

      <CustomTable
        columns={columnsPermissionsTemplatesPage}
        dataSource={mappedData}
        allCount={mappedData?.length}
        // updatePagination={setState}
        // pagination={state.pagination}
        delAction={deleteHandler}
        editAction={editHandler}
      />
    </BoxUi>
  )
}
