import React from 'react'
import cls from './DocGenPreview.module.scss'
import { useLocation, useNavigate } from 'react-router'
import { ITemplate } from '../DocTemplates/templates.interface'
import BoxUi from '../../components/ui/BoxUi/BoxUi'
import ButtonUi from '../../components/ui/ButtonUi/ButtonUi'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { DocEditor } from './components/DocEditor/DocEditor'

type LocationState = {
  item: ITemplate
}

export const DocGenPreview: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { item } = location.state as LocationState

  return (
    <div className={cls.DocPreview}>
      <BoxUi>
        <div className={cls.header}>
          <div className={cls.name}>
            Документ: <span>{item?.name ?? 'документ не найден'}</span>
          </div>
          <ButtonUi color='grey' onClick={() => navigate(-1)}>
            <ArrowLeftOutlined />
            Назад
          </ButtonUi>
        </div>
      </BoxUi>
      <BoxUi>{item && <DocEditor item={item}/>}</BoxUi>
    </div>
  )
}
