import React from 'react'
import styles from './WithLabel.module.scss'
import cn from 'classnames'

interface Props extends React.HTMLProps<HTMLDivElement> {
  children?: JSX.Element
  label?: string
}

const WithLabel: React.FC<Props> = ({ children, label, className, ...props }) => {
  return (
    <div className={cn(styles.container,className)} {...props}>
      {label && <div className={styles.label}>{label}</div>}
      {children && React.cloneElement(children, props)}
    </div>
  )
}

export default WithLabel
