import React from 'react'
import styles from './PayBtn.module.scss'
import { useModalContext } from '../../../context/modalContext'
import PayModal from '../../Modals/PayModal/PayModal'

const PayBtn: React.FC = () => {
  const { setModalComponent } = useModalContext()
  const handler = () => {
    setModalComponent({
      component: <PayModal />,
      maskClosable: true,
    })
  }
  return (
    <div className={styles.container} onClick={handler}>
      оплатить
    </div>
  )
}

export default PayBtn
