import { createContext, Dispatch, SetStateAction, useContext } from 'react'
import moment from 'moment'

export interface ISalaryWeekContext {
  weekFilter: moment.Moment | null
  setWeekFilter: Dispatch<SetStateAction<moment.Moment | null>>
}

export const SalaryWeekContext = createContext<ISalaryWeekContext>({} as ISalaryWeekContext)
export const useSalaryWeekContext = (): ISalaryWeekContext => useContext(SalaryWeekContext)
