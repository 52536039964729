import React, { ReactNode } from 'react'
import { Input } from 'antd'
import { InputProps } from 'antd/lib/input/Input'
import styles from './InputWithLabel.module.scss'
import cn from 'classnames'

interface Props extends InputProps {
  label?: string | ReactNode
  required?: boolean
  error?: string | null
}

const InputWithLabel: React.FC<Props> = ({ label, required, error, className, ...props }) => {
  return (
    <div className={styles.container}>
      <span className={styles.labelWrapper}>
        {label && (
          <p className={styles.label}>
            <>
              {label && label}
              {required && <span>*</span>}
            </>
          </p>
        )}
        {error && <p className={styles.error}>{error}</p>}
      </span>

      <Input className={cn(className, styles.input)} {...props} />
    </div>
  )
}

export default InputWithLabel
