import { createContext, useContext } from 'react'

const contextInitial = {
  setLoad: () => {
    return
  },
  isLoad: false,
}

export interface ILoadSpinContext {
  setLoad(val: boolean): void
  isLoad: boolean
}

export const LoadSpinContext = createContext<ILoadSpinContext>(contextInitial)
export const useLoadSpinContext = (): ILoadSpinContext => useContext(LoadSpinContext)
