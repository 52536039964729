import { docPreset } from '../../../../../../constants/docs/docPreset'
import { BankName } from '../../../../../../enums/banks'
import { classNames } from '../../../../../../utils/classNames'
import { ElementType, ITemplate } from '../../../../../DocTemplates/templates.interface'
import useBankParser from '../../hooks/useBankParser'
import cls from './Preview.module.scss'
import React from 'react'

interface PreviewProps {
  className?: string
  current: ITemplate
}

export const Preview: React.FC<PreviewProps> = ({ current, className = '' }) => {
  const textParser = useBankParser(current)

  return (
    <div id='divToPrint' className={classNames([cls.Preview, className])}>
      <div className={cls.constr}>
        {current?.template?.map((element) => {
          switch (element.type) {
            case ElementType.TEXT:
              return (
                <div
                  key={element.id}
                  className={cls.element}
                  style={{
                    left: `${element.x}px`,
                    top: `${element.y}px`,
                    width: `${element.width}%`,
                  }}
                  dangerouslySetInnerHTML={{ __html: textParser(element.value) }}
                />
              )
            case ElementType.IMG:
              return (
                <img
                  key={element.id}
                  className={classNames([cls.element])}
                  style={{
                    left: `${element.x}px`,
                    top: `${element.y}px`,
                    width: `${element.width ?? 40}%`,
                    transform: `rotate(${element.rotate}deg)`,
                  }}
                  src={element.value}
                  alt='logo'
                />
              )
            case ElementType.LOGO:
              return (
                <img
                  key={element.id}
                  className={classNames([cls.element])}
                  style={{
                    left: `${element.x}px`,
                    top: `${element.y}px`,
                    width: `${element.width ?? 40}%`,
                    transform: `rotate(${element.rotate}deg)`,
                  }}
                  src={docPreset[current.bank as BankName].img}
                  alt='logo'
                />
              )
            case ElementType.STAMP:
              return (
                <img
                  key={element.id}
                  className={classNames([cls.element])}
                  style={{
                    left: `${element.x}px`,
                    top: `${element.y}px`,
                    width: `${element.width ?? 40}%`,
                    transform: `rotate(${element.rotate}deg)`,
                  }}
                  src={docPreset[current.bank as BankName].stamp}
                  alt='logo'
                />
              )
          }
        })}
      </div>
    </div>
  )
}
