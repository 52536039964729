import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INotifications } from '../../dto/notifications/notifications.dto'

export interface INotificationsStore {
  msg: INotifications[]
  newMsg: number
  count: number
  page: number
}

const initialAuthState: INotificationsStore = {
  msg: [],
  newMsg: 0,
  count: 0,
  page: 0,
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialAuthState,
  reducers: {
    setMsg: (state, { payload }: PayloadAction<INotificationsStore>) => {
      state.msg = payload.msg
      state.newMsg = payload.newMsg
      state.count = payload.count
      state.page = payload.page
    },
    readMsg: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.msg = state.msg.map((el) => (el.id !== payload.id ? el : { ...el, isRead: true }))
      state.newMsg = state.newMsg - 1
    },
  },
})

export const { setMsg, readMsg } = notificationsSlice.actions

export default notificationsSlice.reducer
