import { DndContext, DragEndEvent, useDroppable } from '@dnd-kit/core'
import cls from './View.module.scss'
import React, { useId } from 'react'
import { Draggable } from '../Draggable/Draggable'
import { useElementsContext } from '../../../../context/elementsContext'
import { classNames } from '../../../../../../utils/classNames'

interface ViewProps {
  className?: string
  selected?: number
  setSelected: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const View: React.FC<ViewProps> = ({ selected, setSelected, className = '' }) => {
  const { currentItem, setCurrentItem } = useElementsContext()
  const id = useId()
  const { setNodeRef } = useDroppable({
    id: 'droppable',
  })

  function handleDragEnd(event: DragEndEvent) {
    const currentParent = document.getElementById(String(id))
    const currentElem = document.getElementById(String(event.active.id)) as HTMLElement

    event?.active?.id && setSelected(Number(event.active.id))
    const current = currentItem?.template.find((el) => el.id === event.active.id)
    const filtered = currentItem?.template.filter((el) => el.id !== event.active.id)
    if (current && currentElem && currentParent) {
      const parentLeftOffset = currentParent?.offsetLeft ?? 0
      const parentWidth = currentParent?.clientWidth ?? 0
      const rightLimit = parentLeftOffset + parentWidth
      const parentTopOffset = currentParent?.offsetTop ?? 0
      const parentHeight = currentParent?.clientHeight ?? 0
      const topLimit = parentTopOffset + parentHeight
      const elemWidth = currentElem?.clientWidth / 2
      const elemHeight = currentElem?.clientHeight / 2
      const fullX = current.x + event.delta.x + elemWidth + parentLeftOffset
      const fullY = parentTopOffset + elemHeight + event.delta.y + current.y

      let currentX = fullX <= rightLimit ? current.x + event.delta.x : parentWidth - elemWidth
      if (fullX - elemWidth - parentLeftOffset < 0) currentX = 0
      let currentY = fullY <= topLimit ? current.y + event.delta.y : parentHeight - elemHeight
      if (currentY + elemHeight - parentLeftOffset < 0) currentY = 0
      setCurrentItem((prev) => ({
        ...prev,
        template: [
          ...(filtered ?? []),
          {
            ...current,
            x: currentX,
            y: currentY,
          },
        ],
      }))
    }
  }

  return (
    <div id={id} className={classNames([cls.View, className])}>
      <DndContext onDragEnd={handleDragEnd}>
        <div className={cls.constr} ref={setNodeRef}>
          {currentItem?.template.map((element) => (
            <Draggable
              key={element.id}
              parentId={id}
              element={element}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </div>
      </DndContext>
    </div>
  )
}
