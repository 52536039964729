import { useDraggable } from '@dnd-kit/core'
import cls from './Draggable.module.scss'
import React, { useMemo } from 'react'
import useBankParser from '../../hooks/useBankParser'
import { classNames } from '../../../../../../utils/classNames'
import { docPreset } from '../../../../../../constants/docs/docPreset'
import { BankName } from '../../../../../../enums/banks'
import { useElementsContext } from '../../../../context/elementsContext'
import { ElementType, IElem } from '../../../../../DocTemplates/templates.interface'

interface DraggableProps {
  className?: string
  element: IElem
  selected?: number
  parentId: string
  setSelected: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const Draggable: React.FC<DraggableProps> = ({
  element,
  selected,
  setSelected,
  parentId,
  className = '',
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: element.id,
  })
  const { currentItem } = useElementsContext()
  const textParser = useBankParser()

  const handler = () => {
    setSelected(element.id)
  }

  const difX = useMemo(() => {
    const currentParent = document.getElementById(String(parentId))
    const currentElem = document.getElementById(String(element.id)) as HTMLElement
    if (currentElem && currentParent && transform) {
      const elemWidth = currentElem?.clientWidth
      const parentLeftOffset = currentParent?.offsetLeft ?? 0
      const parentWidth = currentParent?.clientWidth ?? 0
      const rightLimit = parentLeftOffset + parentWidth - 3
      const fullX = element.x + transform.x + elemWidth + parentLeftOffset
      if (fullX - elemWidth - parentLeftOffset < 0) return 0
      if (fullX <= rightLimit) return element.x + transform.x

      return parentWidth - elemWidth - 3
    }
  }, [transform, element, parentId])

  const difY = useMemo(() => {
    const currentParent = document.getElementById(String(parentId))
    const currentElem = document.getElementById(String(element.id)) as HTMLElement
    if (currentElem && currentParent && transform) {
      const elemHeight = currentElem?.clientHeight
      const parentTopOffset = currentParent?.offsetTop ?? 0
      const parentHeight = currentParent?.clientHeight ?? 0
      const topLimit = parentTopOffset + parentHeight
      const fullY = parentTopOffset + elemHeight + transform.y + element.y
      if (fullY - elemHeight - parentTopOffset < 0) return 0
      if (fullY <= topLimit) return element.y + transform.y
      return parentHeight - elemHeight
    }
  }, [transform, element, parentId])

  return (
    <>
      {element.type === ElementType.TEXT && (
        <div
          id={String(element.id)}
          onClick={handler}
          ref={setNodeRef}
          className={classNames([cls.Draggable, className], {
            [cls.active]: selected === element.id,
          })}
          style={{
            left: `${transform?.x ? difX : element.x}px`,
            top: `${transform?.y ? difY : element.y}px`,
            width: `${element.width ?? 10}%`,
            zIndex: 2,
          }}
          {...listeners}
          {...attributes}
          dangerouslySetInnerHTML={{ __html: textParser(element.value) }}
        />
      )}
      {element.type === ElementType.IMG && (
        <img
          className={classNames([cls.img, className], {
            [cls.active]: selected === element.id,
          })}
          style={{
            left: `${transform?.x ? difX : element.x}px`,
            top: `${transform?.y ? difY : element.y}px`,
            width: `${element.width ?? 10}%`,
            transform: `rotate(${element.rotate}deg)`,
            zIndex: 1,
          }}
          id={String(element.id)}
          onClick={handler}
          ref={setNodeRef}
          src={element.value}
          {...listeners}
          {...attributes}
          alt='logo'
        />
      )}
      {element.type === ElementType.LOGO && (
        <img
          className={classNames([cls.img, className], {
            [cls.active]: selected === element.id,
          })}
          style={{
            left: `${transform?.x ? difX : element.x}px`,
            top: `${transform?.y ? difY : element.y}px`,
            width: `${element.width ?? 10}%`,
            transform: `rotate(${element.rotate}deg)`,
            zIndex: 1,
          }}
          id={String(element.id)}
          onClick={handler}
          ref={setNodeRef}
          src={docPreset[currentItem?.bank as BankName].img}
          {...listeners}
          {...attributes}
          alt='logo'
        />
      )}
      {element.type === ElementType.STAMP && (
        <img
          className={classNames([cls.img, className], {
            [cls.active]: selected === element.id,
          })}
          style={{
            left: `${transform?.x ? difX : element.x}px`,
            top: `${transform?.y ? difY : element.y}px`,
            width: `${element.width ?? 10}%`,
            transform: `rotate(${element.rotate}deg)`,
            zIndex: 1,
          }}
          id={String(element.id)}
          onClick={handler}
          ref={setNodeRef}
          src={docPreset[currentItem?.bank as BankName].stamp}
          {...listeners}
          {...attributes}
          alt='stamp'
        />
      )}
    </>
  )
}
