import React from 'react'
import cls from './SliderUi.module.scss'
import cn from 'classnames'
import Slider, { SliderSingleProps } from 'antd/lib/slider'

interface SliderUiProps extends SliderSingleProps {
  className?: string
  label?: string
}

export const SliderUi: React.FC<SliderUiProps> = ({ label, className, ...props }) => {
  return (
    <div className={cls.wrapper}>
      {label && <p className={cls.label}>{label}</p>}
      <Slider className={cn([cls.SliderUi, className])} {...props} />
    </div>
  )
}
