import React, { memo, useCallback } from 'react'
import { ClusterOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import styles from './HeaderLogo.module.scss'
import { useNavigate } from 'react-router-dom'
import { useDefaultPath } from '../../../hooks/useDefautPath'

const HeaderLogo: React.FC = memo(() => {
  const navigate = useNavigate()
  const redirect = useDefaultPath()

  const handler = useCallback(() => {
    navigate(redirect)
  }, [redirect])

  return (
    <div className={styles.container} onClick={handler}>
      <Avatar size={30} className={styles.avatar} icon={<ClusterOutlined />} />
      <span className={styles.title}>CRM system</span>
    </div>
  )
})

export default HeaderLogo
