export enum ImgType {
  SIGNATURE = 'signature',
  FOTO = 'foto',
  STAMP = 'stamp',
  ADDITION = 'addition',
}

export enum SignatureType {
  SIGN1 = 'sign1',
  SIGN2 = 'sign2',
  SIGN3 = 'sign3',
  SIGN4 = 'sign4',
  SIGN5 = 'sign5',
  SIGN6 = 'sign6',
  SIGN7 = 'sign7',
  SIGN8 = 'sign8',
  SIGN9 = 'sign9',
  SIGN10 = 'sign10',
  SIGN11 = 'sign11',
  SIGN12 = 'sign12',
  SIGN13 = 'sign13',
  SIGN14 = 'sign14',
  SIGN15 = 'sign15',
  SIGN16 = 'sign16',
  SIGN17 = 'sign17',
  SIGN18 = 'sign18',
  SIGN19 = 'sign19',
  SIGN20 = 'sign20',
  SIGN21 = 'sign21',
  SIGN22 = 'sign22',
  SIGN23 = 'sign23',
  SIGN24 = 'sign24',
  SIGN25 = 'sign25',
  SIGN26 = 'sign26',
  SIGN27 = 'sign27',
  SIGN28 = 'sign28',
  SIGN29 = 'sign29',
  SIGN30 = 'sign30',
  SIGN31 = 'sign31',
  SIGN32 = 'sign32',
  SIGN33 = 'sign33',
  SIGN34 = 'sign34',
  SIGN35 = 'sign35',
  SIGN36 = 'sign36',
  SIGN37 = 'sign37',
  SIGN38 = 'sign38',
  SIGN39 = 'sign39',
  SIGN40 = 'sign40',
  SIGN41 = 'sign41',
  SIGN42 = 'sign42',
}

export enum StampType {
  ABSOLUTE = 'absolute',
  AKBARS = 'akbars',
  ALFA = 'alfa',
  ATB = 'atb',
  AVANGARD = 'avangard',
  BKS = 'bks',
  BSPB = 'bspb',
  C2BR = 'c2br',
  CBR = 'cbr',
  CITY = 'city',
  COAST = 'coast',
  DOMRF = 'domrf',
  FSB = 'fsb',
  FSB2 = 'fsb2',
  GAZPROM = 'gazprom',
  GENBANK = 'genbank',
  HLINOV = 'HLINOV',
  HOMECREDIT = 'homecredit',
  KAZAH = 'kazah',
  LOCKOBANK = 'lockobank',
  MINBANK = 'minbank',
  MKB = 'mkb',
  MTS = 'mts',
  MVD_KAZAH = 'mvd_kazah',
  MVD = 'mvd',
  MVD2 = 'mvd2',
  MVD3 = 'mvd3',
  NBK = 'nbk',
  NIKO = 'niko',
  OPEN = 'open',
  OTP = 'otp',
  POCHTA = 'pochta',
  PROC = 'proc',
  PSBANK = 'psbank',
  RAIFFEISEN = 'raiffeisen',
  RENCREDIT = 'rencredit',
  RNCB = 'rncb',
  ROSBANK = 'rosbank',
  ROSTRAH = 'rostrah',
  RSB = 'rsb',
  RSHB = 'rshb',
  SBER = 'sber',
  SETELEM = 'setelem',
  SKBBANK = 'skbbank',
  SOVCOMBANK = 'sovcombank',
  TINKOFF = 'tinkoff',
  UBRR = 'ubrr',
  UNICREDIT = 'unicredit',
  URALSIB = 'uralsib',
  VBR = 'vbr',
  VTB = 'vtb',
  ZAPSIB = 'zapsib',
}

export enum PhotoType {
  PHOTO1 = 'photo1',
  PHOTO2 = 'photo2',
  PHOTO3 = 'photo3',
  PHOTO4 = 'photo4',
  PHOTO5 = 'photo5',
  PHOTO6 = 'photo6',
  PHOTO7 = 'photo7',
  PHOTO8 = 'photo8',
  PHOTO9 = 'photo9',
  PHOTO10 = 'photo10',
  PHOTO11 = 'photo11',
  PHOTO12 = 'photo12',
  PHOTO13 = 'photo13',
  PHOTO14 = 'photo14',
  PHOTO15 = 'photo15',
  PHOTO16 = 'photo16',
  PHOTO17 = 'photo17',
  PHOTO18 = 'photo18',
  PHOTO19 = 'photo19',
  PHOTO20 = 'photo20',
  PHOTO21 = 'photo21',
  PHOTO22 = 'photo22',
  PHOTO23 = 'photo23',
  PHOTO24 = 'photo24',
  PHOTO25 = 'photo25',
  PHOTO26 = 'photo26',
  PHOTO27 = 'photo27',
  PHOTO28 = 'photo28',
  PHOTO29 = 'photo29',
  PHOTO30 = 'photo30',
  PHOTO31 = 'photo31',
  PHOTO32 = 'photo32',
  PHOTO33 = 'photo33',
  PHOTO34 = 'photo34',
  PHOTO35 = 'photo35',
  PHOTO36 = 'photo36',
  PHOTO37 = 'photo37',
  PHOTO38 = 'photo38',
  PHOTO39 = 'photo39',
  PHOTO40 = 'photo40',
  PHOTO41 = 'photo41',
  PHOTO42 = 'photo42',
  PHOTO43 = 'photo43',
  PHOTO44 = 'photo44',
  PHOTO45 = 'photo45',
  PHOTO46 = 'photo46',
  PHOTO47 = 'photo47',
  PHOTO48 = 'photo48',
  PHOTO49 = 'photo49',
  PHOTO50 = 'photo50',
  PHOTO51 = 'photo51',
  PHOTO52 = 'photo52',
  PHOTO53 = 'photo53',
  PHOTO54 = 'photo54',
  PHOTO55 = 'photo55',
  PHOTO56 = 'photo56',
  PHOTO57 = 'photo57',
  PHOTO58 = 'photo58',
  PHOTO59 = 'photo59',
  PHOTO60 = 'photo60',
  PHOTO61 = 'photo61',
}

export enum AdditionType {
  BARCODE = 'barcode',
  BORDER = 'border',
  LINE_H = 'line_h',
  CIRCLE = 'circle',
  CIRCLE_D = 'circle_d',
  SQUARE = 'square',
  SQUARE_D = 'square_d',
}
