import React, { useCallback } from 'react'
import { ITransaction } from '../payment.interface'
import styles from '../PayModal.module.scss'
import { Form, Input } from 'antd'
import ButtonUi from '../../../ui/ButtonUi/ButtonUi'
import axiosInstance from '../../../../config/axios'
import { serverPath } from '../../../../constants/api-endpoints'
import { errorMapper } from '../../../../utils/errorMapper'
import { ErrorMessages } from '../../../../constants/errors'
import { useMessage } from '../../../../hooks/useMessage'

interface Props {
  setTransaction: React.Dispatch<React.SetStateAction<ITransaction | undefined>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const AddTransaction: React.FC<Props> = ({ setTransaction, setLoading }) => {
  const { success, error } = useMessage()

  const submit = useCallback(async (e: { amount: number }) => {
    setLoading(true)
    axiosInstance
      .post<ITransaction>(serverPath.BILLING, { amount: Number(e.amount) })
      .then(({ data }) => {
        setTransaction(data)
        success('Заявка добавлена')
      })
      .catch((e) => {
        error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <Form name='add_transaction' onFinish={submit} className={styles.addWrapper}>
      <div className={styles.title}>Создать заявку на оплату:</div>

      <div className={styles.desc}>Введите сумму usdt(trc20):</div>
      <Form.Item
        className={styles.input}
        name={'amount'}
        rules={[{ required: true, message: 'введите сумму' }]}
      >
        <Input placeholder={`введите сумму`} type='number' min={200} />
      </Form.Item>
      <div className={styles.subTitle}>
        * Минимальная сумма пополнения 200$ (cтоимость месячной подписки 200$)
      </div>
      <ButtonUi htmlType='submit' color='green'>
        создать заявку
      </ButtonUi>
    </Form>
  )
}

export default AddTransaction
