import React, { useCallback, useState } from 'react'
import { IRecallDto } from '../../../dto/recall/recall.dto'
import styles from './RecallEventModal.module.scss'
import { formatData } from '../../../utils/time'
import ButtonUi from '../../ui/ButtonUi/ButtonUi'
import { DatePicker, Form } from 'antd'
import { useModalContext } from '../../../context/modalContext'
import axiosInstance from '../../../config/axios'
import { serverPath } from '../../../constants/api-endpoints'
import { errorMapper } from '../../../utils/errorMapper'
import { ErrorMessages } from '../../../constants/errors'
import { useMessage } from '../../../hooks/useMessage'
import { useNavigate } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'

interface Props {
  recall: IRecallDto
}

const RecallEventModal: React.FC<Props> = ({ recall }) => {
  const { setModalComponent } = useModalContext()
  const { error, success } = useMessage()
  const navigate = useNavigate()
  const [disable, setDisable] = useState(false)

  const goToClient = useCallback(() => {
    navigate(routersPath.TASK_PAGE_RECALL, {
      state: {
        recallId: recall.id,
      },
    })
    setModalComponent({ component: null })
  }, [])

  const updateRecall = async (e: { recallDate: any }) => {
    try {
      setDisable(true)
      await axiosInstance.patch(serverPath.RECALL + '/' + recall.id, {
        recallDate: e.recallDate.format(),
      })
      success('Перезвон обновлен успешно')
      setModalComponent({ component: null })
    } catch (e) {
      error(errorMapper(ErrorMessages.CREATE_DATA, e), 'Ошибка добавления перезвона')
    } finally {
      setDisable(false)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Перезвон по клиенту {recall.client?.fullName}</div>
      <div className={styles.time}>назначен на {formatData(recall.recallDate)}</div>
      <ButtonUi color='green' onClick={goToClient}>
        Перейти на клиента
      </ButtonUi>
      <Form name='basic' onFinish={updateRecall} className={styles.form}>
        <div className={styles.recall}>
          <span>Перенести на </span>
          <Form.Item rules={[{ required: true, message: 'выберите время' }]} name='recallDate'>
            <DatePicker showTime={{ format: 'HH:mm' }} />
          </Form.Item>
        </div>
        <ButtonUi className={styles.submit} htmlType='submit' disabled={disable}>
          Перенести
        </ButtonUi>
      </Form>
    </div>
  )
}

export default RecallEventModal
