import React, { useCallback, useEffect, useState } from 'react'
import cls from './EditPermissionTemplate.module.scss'
import { IUserPermissionTemplate } from '../../../../dto/userPermissions.dto'
import TitleUi from '../../../../components/ui/TitleUi/TilteUi'
import { Checkbox, Divider, Form, Input, Tooltip } from 'antd'
import ButtonUi from '../../../../components/ui/ButtonUi/ButtonUi'
import { useModalContext } from '../../../../context/modalContext'
import { useMessage } from '../../../../hooks/useMessage'
import axiosInstance from '../../../../config/axios'
import { errorMapper } from '../../../../utils/errorMapper'
import { ErrorMessages } from '../../../../constants/errors'
import { serverPath } from '../../../../constants/api-endpoints'
import WithLabel from '../../../../components/ui/WithLabel/WithLabel'
import TextArea from 'antd/lib/input/TextArea'
import CustomManagerSelect from '../../../../components/ui/CustomManagerSelect/CustomManagerSelect'
import { EUserRoles } from '../../../../enums/roles'
import { AxiosResponse } from 'axios'
import { ManagerLightResponseDto } from '../../../../dto/user/user.dto'
import { userPermissionsList } from '../../../../constants/userPermissions'
import { InfoCircleOutlined } from '@ant-design/icons'

interface IProps {
  current: IUserPermissionTemplate
  refresh: () => void
}

export const EditPermissionTemplate: React.FC<IProps> = ({ current, refresh }) => {
  const [disable, setDisable] = useState(false)
  const { setModalComponent } = useModalContext()
  const { error, success } = useMessage()
  const [users, setUsers] = useState<ManagerLightResponseDto[]>([])

  const getData = useCallback(async () => {
    try {
      setDisable(true)
      const { data: dataUser }: AxiosResponse<ManagerLightResponseDto[]> = await axiosInstance.get(
        serverPath.USERS_LIGHT,
      )
      setUsers(dataUser.filter((el) => el.role !== EUserRoles.OPERATOR))
    } catch (e) {
      error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
    } finally {
      setDisable(false)
    }
  }, [])

  useEffect(() => {
    getData().then()
  }, [])

  const updateHandler = async (values: {
    name: string
    description: string
    users: ManagerLightResponseDto[]
    permissions: { [key: string]: boolean | undefined }
  }) => {
    const permissionIds = Object.keys(values.permissions).filter((el) => values.permissions[el])
    const userIds = values.users
    const permissionLvlId = current.id

    try {
      setDisable(true)
      await axiosInstance.patch(`${serverPath.PERMISSIONS_TEMPLATES}/${current.id}`, {
        name: values.name,
        description: values.description,
      })
      await axiosInstance.post(`${serverPath.PERMISSIONS_LVL}`, {
        permissionLvlId,
        permissionIds,
      })
      await axiosInstance.post(`${serverPath.PERMISSIONS_USERS}`, {
        permissionLvlId,
        userIds,
      })
      success('Группа разрешений обновлена успешно')
      refresh()
      setModalComponent({ component: null })
    } catch (e) {
      error(errorMapper(ErrorMessages.UPDATE_DATA, e), 'Ошибка обновления группы разрешений')
    } finally {
      setDisable(false)
    }
  }

  return (
    <Form
      className={cls.AddPermissionTemplate}
      name='edit_permissions'
      onFinish={updateHandler}
      initialValues={{
        name: current.name,
        description: current.description,
        users: current?.users.map((el) => el.id),
        permissions: current.permissions.reduce((acc, el) => ({ ...acc, [el.id]: true }), {}),
      }}
    >
      <div className={cls.header}>
        <TitleUi colored={false}>Редактирование группы разрешений</TitleUi>
      </div>
      <Divider />
      <div className={cls.body}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'введите имя группы',
            },
            {
              min: 3,
              message: 'минимальная длинна 3 символа',
            },
          ]}
          validateTrigger='onSubmit'
          name='name'
        >
          <WithLabel label={'Имя группы:'}>
            <Input placeholder='введите имя группы' disabled={disable} />
          </WithLabel>
        </Form.Item>
        <Form.Item
          rules={[
            {
              min: 3,
              message: 'минимальная длинна 3 символа',
            },
          ]}
          validateTrigger='onSubmit'
          name='description'
        >
          <WithLabel label={'Описание группы:'}>
            <TextArea rows={3} placeholder='Описание группы' />
          </WithLabel>
        </Form.Item>
        <Form.Item rules={[{ required: true, message: 'выберите менеджера' }]} name='users'>
          <WithLabel label={'Менеджеры:'}>
            <CustomManagerSelect
              className={cls.userSelect}
              data={users.map((el) => ({ value: el.id, title: el.nickName }))}
            />
          </WithLabel>
        </Form.Item>

        <WithLabel label={'Разрешения:'}>
          <div className={cls.permissions}>
            {userPermissionsList.map((el) => (
              <div className={cls.row} key={el.id}>
                <Form.Item name={['permissions', el.id]} valuePropName='checked'>
                  <Checkbox>{el.name}</Checkbox>
                </Form.Item>
                <Tooltip key={el.id} title={el.description} placement='topLeft'>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            ))}
          </div>
        </WithLabel>
      </div>
      <Divider />
      <div className={cls.actions}>
        <ButtonUi type='primary' onClick={() => setModalComponent({ component: null })} color='red'>
          Отменить
        </ButtonUi>
        <ButtonUi
          type='primary'
          onClick={refresh}
          color='green'
          disabled={disable}
          htmlType='submit'
        >
          Сохранить
        </ButtonUi>
      </div>
    </Form>
  )
}
