import { BankName } from '../../enums/banks'

export enum ElementType {
  TEXT = 'text',
  IMG = 'image',
  LOGO = 'logo',
  STAMP = 'stamp',
}

export interface IElem {
  id: number
  label: string
  type: ElementType
  hidden: boolean
  value: string
  width?: number
  rotate?: number
  x: number
  y: number
}
export interface ITemplate {
  createdAt: string
  crmId: string
  id: string
  bank: BankName
  name: string
  template: IElem[]
  updatedAt: string
  shareHash: string | null
}

export interface IBackTemplate extends Omit<ITemplate, 'template'> {
  template: string
}
