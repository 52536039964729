// eslint-disable-next-line
export const errorMapper = (message: string, e: any) => {
  if (e?.response?.data?.message.includes('already exists')) {
    return 'Пользователь с таким логином уже существует'
  }
  switch (e?.response?.data?.message) {
    case 'name must be unique':
      return 'Имя должно быть уникальным'
    case 'phone must be unique':
      return 'телефон должен быть уникальным'
    case 'email must be unique':
      return 'email должен быть уникальным'
    case 'Not found task for this user pls check filter':
      return 'Нет текущих задач'
    case 'New base not found in this crm':
      return 'Нет новых баз данных'
    case 'Filter not found':
      return 'Нет фильтров для менеджера'
    case 'Please billing update':
      return 'Срок подписки на CRM окончен'
    case 'User is already online':
      return 'Пользователь уже залогинился'
    case 'Empty base':
      return 'В базе нет новых клиентов, выберите другую базу'
    case 'Zone not found':
      return 'Сервер не найден на cloudflare'
    case 'Z_BUF_ERROR':
      return 'Ошибка сервиса FlyPay'
    case 'The user was not created':
      return 'Пользователь не создан, попробуйте еще раз'
    default:
      return message
  }
}
