import { setLinks } from './reducer'
import { AppDispatch } from '../index'
import axiosInstance from '../../config/axios'
import { AxiosResponse } from 'axios'
import { ILink } from '../../dto/links'
import { serverPath } from '../../constants/api-endpoints'

export const getLinks = () => async (dispatch: AppDispatch) => {
  try {
    const { data }: AxiosResponse<ILink[]> = await axiosInstance.get(serverPath.LINKS)
    dispatch(setLinks(data))
  } catch (e) {
    console.log(e)
  }
}
