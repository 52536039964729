import React, { HTMLAttributes } from 'react'
import cn from 'classnames'
import cls from './ErrorAppPage.module.scss'
import { WarningOutlined } from '@ant-design/icons'
import ButtonUi from '../../../components/ui/ButtonUi/ButtonUi'
import { routersPath } from '../../../router/routes-path'

interface ErrorAppPageProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  error: Error | null
}

export const ErrorAppPage: React.FC<ErrorAppPageProps> = ({ className, error }) => {
  return (
    <div className={cn([cls.ErrorAppPage, className])}>
      <WarningOutlined className={cls.icon} />
      <div className={cls.title}>Ошибка приложения</div>
      <div className={cls.message}>обратитесь к администратору </div>
      <div className={cls.errWrapper}>
        <p className={cls.errMessage}>Ошибка : {error?.message}</p>
        <p className={cls.errStack}>{error?.stack}</p>
      </div>
      <ButtonUi color='red' onClick={() => (window.location.href = routersPath.MAIN_PAGE)}>
        Перейти на главную
      </ButtonUi>
    </div>
  )
}
