import React, { useEffect } from 'react'
import styles from './ManagerHeader.module.scss'
import HeaderLogo from '../../ui/headerLogo/HeaderLogo'
import { ManagerProfileDrop } from '../../../menu/dropdown/managerProfile/managerProfile'
import { useNavigate } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import { getTestsCount } from '../../../store/tests/thunk'
import { useAppDispatch } from '../../../store/hooks'
import { useTestsCountSelector } from '../../../store/selectors/tests'
import { useCrmPermission } from '../../../store/selectors/crmInfo'
import { getLinks } from '../../../store/links/thunk'
import { useSocketContext } from '../../../context/socketContext'
import { SocketEvents } from '../../../enums/socket'
import { useUserSelector } from '../../../store/selectors/auth'
import { getUserPermissions } from '../../../store/userPermissions/thunk'

const ManagerHeader: React.FC = () => {
  const dispatch = useAppDispatch()
  const testsCount = useTestsCountSelector()
  const permissions = useCrmPermission()
  const { subscribeToEvent, unSubscribeToEvent } = useSocketContext()
  const user = useUserSelector()

  useEffect(() => {
    const updatePermissions = (val: string[]) => {
      dispatch(getUserPermissions())
    }
    subscribeToEvent(SocketEvents.UPDATE_USERS_PERMISSIONS, updatePermissions)
    return () => unSubscribeToEvent(SocketEvents.UPDATE_USERS_PERMISSIONS, updatePermissions)
  }, [user])

  useEffect(() => {
    void dispatch(getTestsCount())
    void dispatch(getLinks())
  }, [])

  const navigate = useNavigate()
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <HeaderLogo />
        <div className={styles.dropdown}>
          {permissions.tests && (
            <div className={styles.btnWrapper} onClick={() => navigate(routersPath.MANAGER_TESTS)}>
              <div className={styles.testBtn}>Тесты</div>
              {testsCount > 0 && <div className={styles.tag}>{testsCount}</div>}
            </div>
          )}
          <ManagerProfileDrop />
        </div>
      </div>
    </div>
  )
}

export default ManagerHeader
