import React from 'react'
import { Button, ButtonProps } from 'antd'
import cn from 'classnames'
import './ButtonUi.scss'

interface Props extends ButtonProps {
  color?: 'red' | 'green' | 'blue' | 'yellow' | 'grey'
  outlined?: boolean
  size?: 'small' | 'middle' | 'large'
  fullWidth?: boolean
}

const ButtonUi: React.FC<Props> = ({
  outlined,
  color = 'blue',
  className,
  children,
  fullWidth,
  size,
  ...props
}) => {
  return (
    <Button
      {...props}
      className={cn(
        'buttonUi',
        {
          outlined: outlined,
        },
        { ['fullWidth']: fullWidth },
        `buttonUi_${color}`,
        className,
        { ['small']: size === 'small' },
        { ['middle']: size === 'middle' },
      )}
      size={size}
    >
      {children}
    </Button>
  )
}

export default ButtonUi
